import styled from "styled-components"

import { screens } from "../helpers/device"
import { cssRem, css } from "../helpers/sizes"


const Button = styled.button`

    ${p => css(p['bgColor'], "background-color")}
    ${p => css(p['color'], "color")}
    ${p => css(p['boxShadow'], "box-shadow")}
    ${p => css(p['opacity'], "opacity")}
    
    ${screens.map(s => {
        return p => `@media (min-width:${s.min}) { 
            ${cssRem(p[s.id + 'w'], "width")}
            ${cssRem(p[s.id + 'h'], "height")}

            ${cssRem(p[s.id + 'p'], "padding")}
            ${cssRem((p[s.id + 'pt'] || p[s.id + 'py']), "padding-top")}
            ${cssRem((p[s.id + 'pr'] || p[s.id + 'px']), "padding-right")}
            ${cssRem((p[s.id + 'pb'] || p[s.id + 'py']), "padding-bottom")}
            ${cssRem((p[s.id + 'pl'] || p[s.id + 'px']), "padding-left")}

            ${cssRem(p[s.id + 'm'], "margin")}
            ${cssRem((p[s.id + 'mt'] || p[s.id + 'my']), "margin-top")}
            ${cssRem((p[s.id + 'mr'] || p[s.id + 'mx']), "margin-right")}
            ${cssRem((p[s.id + 'mb'] || p[s.id + 'my']), "margin-bottom")}
            ${cssRem((p[s.id + 'ml'] || p[s.id + 'mx']), "margin-left")}

            ${cssRem((p[s.id + 'borderRadius']), "border-radius")}
        }`     
    })}
    
    display: ${p => p.display || "flex"};
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat';

    border: 0;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    } 

    & > * {
        cursor: pointer;
    }
`

Button.defaultProps = {
    size: 'medium',
    bgColor: 'primary',
    fontColor: 'white',
}

export default Button