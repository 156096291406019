import styled from "styled-components";

import { colors } from "../helpers/colors";
import { screens } from "../helpers/device";
import { css, cssRem, cssBool } from "../helpers/sizes";

const Link = styled.a`
    ${(p) => css(p["fontColor"], "color", colors["primary"])}
    ${(p) => css(p["border"], "border-width")}
    ${(p) => css(p["borderBottom"], "border-bottom")}
    ${(p) => css(p["borderStyle"], "border-style", p["border"] ? "solid" : "")}
    ${(p) => css(p["borderColor"], "border-color", p["border"] ? "black" : "")}

    ${(p) => cssBool(p["bold"], "font-weight: 700")}
    ${(p) => cssBool(p["bolder"], "font-weight: 600")}
    ${(p) => cssBool(p["medium"], "font-weight: 500")}

    ${screens.map((s) => {
        return (p) => `@media (min-width:${s.min}) { 

            ${css(p[s.id + "size"], "font-size")}
            ${css(p[s.id + "align"], "text-align")}
            ${css(p[s.id + "lineHeight"], "line-height")}
        
            ${css(p[s.id + "display"], "display")}
            ${css(p[s.id + "alignItems"], "align-items")}
            
            ${cssRem(p[s.id + "p"], "padding")}
            ${cssRem(p[s.id + "pt"] || p[s.id + "py"], "padding-top")}
            ${cssRem(p[s.id + "pr"] || p[s.id + "px"], "padding-right")}
            ${cssRem(p[s.id + "pb"] || p[s.id + "py"], "padding-bottom")}
            ${cssRem(p[s.id + "pl"] || p[s.id + "px"], "padding-left")}

            ${cssRem(p[s.id + "m"], "margin")}
            ${cssRem(p[s.id + "mt"] || p[s.id + "my"], "margin-top")}
            ${cssRem(p[s.id + "mr"] || p[s.id + "mx"], "margin-right")}
            ${cssRem(p[s.id + "mb"] || p[s.id + "my"], "margin-bottom")}
            ${cssRem(p[s.id + "ml"] || p[s.id + "mx"], "margin-left")}
        }`;
    })}

    cursor: pointer;
    user-select: none;
    text-decoration: none;

    /* &:active {
        border-bottom: 1px solid grey;
    } */

    &:hover {
        text-decoration: ${(p) => (p["decoration"] ? p["decoration"] : "underline")};
        ${(p) => css(p["hover-bgColor"], "background-color")}
    }
`;

export default Link;
