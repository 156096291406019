export const planText = [
    {
        section: [
            {
                id: "1.1",
                question: "Heart Rate",
                answer:
                    "Heart rate (the wave of blood in the artery created by contraction of the left ventricle during a cardiac cycle) as beats/min.",
            },
            {
                id: "1.2",
                question: "Respiratory Rate",
                answer:
                    "Respiratory rate (the number of breaths a person takes per minute) as breaths/min.",
            },
            {
                id: "1.3",
                question: "Heart Rate Variability (HRV)",
                answer:
                    "Heart rate variability (the change in the time intervals between adjacent heartbeats) in ms.",
            },
            // {
            //     id: "1.4",
            //     question: "Blood Oxygen",
            //     answer:
            //         "Blood-oxygen saturation (the oxygen level (oxygen saturation) of the blood) as %.",
            // },
            {
                id: "1.5",
                question: "Blood Pressure",
                answer:
                    `Blood pressure (the force of the blood pushing against the artery walls during contraction and relaxation of the heart) as systolic/diastolic mmHg.`,
            },
            // {
            //     id: "1.6",
            //     question: "Skin Temperature",
            //     answer: `Skin temperature (the temperature of the skin on the forehead) in °C.`,
            //     extra: "COMING SOON",
            // },
        ],
    },
];

export default planText;
