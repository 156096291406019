import React, {useContext, useRef, useState, useEffect} from "react";
import styled from "styled-components";

import {GlobalContext} from "../../App";

import imgBg from "../../assets/images/docme_redesigned/DocMe_for_Me_header.png";
import imgBgMobile from "../../assets/images/docme_redesigned/DocMe_Header_mobile.png";

import imgQRlinkToDocme from "../../assets/images/docme/qrCode.png";
import imgMonitorYourHealth from "../../assets/images/docme_redesigned/DocMe_for_Me_monitor_your_health.png";
import imgGetActionableInsights from "../../assets/images/docme_redesigned/DocMe_A_comprehensive_view_of_you.png";
import imgTrackImportant from "../../assets/images/docme_redesigned/Track_what_is_important_to_you.png";
import imgReceiveInsights from "../../assets/images/docme_redesigned/1.png";
import imgTheMoreYouLearn from "../../assets/images/docme_redesigned/DocMe_for_me_get_actionable_insights_new_V2_.png";
import imgDocmeYourData from "../../assets/images/docme_redesigned/DocMe_Your_data.png";

import imgBadge from "../../assets/images/docme_redesigned/PH_badge.png";

import imgArrowRightBlack from "../../assets/images/docme_redesigned/arrow_right_black.svg";
import imgArrowRightBlue from "../../assets/images/docme_redesigned/Arrow_right_blue.svg";
import imgArrowRight from "../../assets/images/icons/icon_24px_right_arrow.svg";

// import imgArrowDown from "../../assets/images/docme_redesigned/Arrow_56_px.svg";

import imgAppStore from "../../assets/images/docme/App_store_badge – 2022.svg";
import imgGooglePlay from "../../assets/images/GooglePlay.svg";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import "../../assets/styles/index.css";
import parsePhoneNumber from "libphonenumber-js";

import axios from "axios";

import imgSmartVision from "../../assets/images/docme_redesigned/SmartVisions.png";

// import imgMeetDocme from "../../assets/images/docme_redesigned/DocMe.png";
// import imgMeetHealth from "../../assets/images/docme_redesigned/Apple_Health.png";

import imgP_NHS from "../../assets/images/docme/Partner_1_NHS_OUH.png";
import imgP_Cam from "../../assets/images/docme/Partner_2_Cambridge.png";
import imgP_Dell from "../../assets/images/docme/Partner_4_Dell_Medical_School.png";
import imgP_Kings from "../../assets/images/docme/Partner_Kings_College.png";
import imgP_Pan from "../../assets/images/docme/Partner_5_Panacea_Stars.png";
import imgP_NHSMAA from "../../assets/images/docme/Partner_7_NHS_Market_Access_Accelerator.png";
import imgP_NIHR from "../../assets/images/docme/Partner_8_National_Institute_for_Health_Research_NIHR.png";
import imgP_plug_play from "../../assets/images/docme/Partner_9_plug_play.png";
import imgP_europ_union from "../../assets/images/docme/Partner_10_europ_union.png";
import imgP_google from "../../assets/images/docme/Partner_11_google.png";
import imgP_innovate from "../../assets/images/docme/Partner_12_innovate.png";
import imgP_nvidia from "../../assets/images/docme/Partner_13_nvidia.png";

import heartOutline from "../../assets/images/docme_redesigned/heart_unselected_38px.svg";
import heartSelected from "../../assets/images/docme_redesigned/heart_selected_38px.svg";

import imgBgCV from "../../assets/images/docme_redesigned/DocMe_Technology_1.jpg";
import imgBgXAI from "../../assets/images/docme_redesigned/DocMe_Technology_3.jpg";
import imgBgEML from "../../assets/images/docme_redesigned/DocMe_Technology_2.jpg";
import imgIconBack from "../../assets/images/Icon_back.svg";

import nhsLogoSection from "../../assets/images/docme_redesigned/nhs_logo_section.jpg";
import gdprLogoSection from "../../assets/images/docme_redesigned/gdpr_logo_section.jpg";
import icosLogoSection from "../../assets/images/docme_redesigned/ico_logo_section.jpg";
import hippaLogoSection from "../../assets/images/docme_redesigned/hippa_logo_section.jpg";

import imgBgDocForMe5 from "../../assets/images/docme/perfexia_docme_for_me_5.jpg";

import imgPhone1 from "../../assets/images/docme/How_DocMe_works_1.png";
import imgPhone2 from "../../assets/images/docme/Ho_DocMe_works_2.png";
import imgPhone3 from "../../assets/images/docme/How_DocMe_works_3.png";

import imgProfile from "../../assets/images/docme_redesigned/Profile.svg";
import imgAlexis from "../../assets/images/docme_redesigned/Alexis_Abayomi.png";
import imgReetesh from "../../assets/images/docme_redesigned/Reetesh_Aanand.png";
import imgTimur from "../../assets/images/docme_redesigned/DocMe_Testimonial_3.png";
import imgGleb from "../../assets/images/docme_redesigned/Gleb_Galkin.png";
import imgKamil from "../../assets/images/docme_redesigned/Kamil_Galdzicki.png";
import imgDanielePackard from "../../assets/images/docme_redesigned/Daniele Packard.jpeg";
import imgJackDavis from "../../assets/images/docme_redesigned/Jack Davis.jpeg";
import imgRobiulIslam from "../../assets/images/docme_redesigned/Robiul Islam.jpeg";
import imgJessicaChu from "../../assets/images/docme_redesigned/Jessica Chu.jpeg";
import imgRickMcCartney from "../../assets/images/docme_redesigned/Rick McCartney.jpeg";

import Content from "../atoms/Content";
import Label from "../atoms/Label";
import Link from "../atoms/Link";
import Image from "../atoms/Image";
import Button from "../atoms/Button";
import NavbarDocMe from "../organisms/NavbarDocMe";

//animation
import { phoneTransition } from "../helpers/animation";

//carousel
import {
    CarouselProvider,
    Slider,
    Slide,
    ButtonBack,
    ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import imgHowDoes1 from "../../assets/images/docme/How_does_DocMe_app_work.jpg";
import imgHowDoes2 from "../../assets/images/docme/How_does_DocMe_app_work_2.jpg";
import imgHowDoes3 from "../../assets/images/docme/How_does_DocMe_app_work_3.jpg";
import imgHowDoes4 from "../../assets/images/docme/How_does_DocMe_app_work_4.jpg";
import imgCarouselLeft from "../../assets/images/docme/Arrow_nav_left.svg";
import imgCarouselRight from "../../assets/images/docme/Arrow_nav_right.svg";
import imgProductHuntUpvote from "../../assets/images/docme/ProductHunt_Upvotes@2x.png";
import bgCard1 from "../../assets/images/docme/bg1.png";
import bgCard2 from "../../assets/images/docme/bg2.png";
import bgCard3 from "../../assets/images/docme/bg3.png";
import "../../assets/styles/index.css";
import Plan from "./freePlan/Plan";
import pfa from "../../assets/images/docme_redesigned/PFA.png"

import MetaTags from "react-meta-tags";
import SliderSlick from "react-slick";
import AssesmentCard from "./docme/AssesmentCard";

const ComputerVision = () => {
    const global = useContext(GlobalContext);
    const [phNum, setPhNum] = useState();
    const [sent, setSent] = useState(false);

    const [heart1, setHeart1] = useState(false);
    const [heart2, setHeart2] = useState(false);
    const [heart3, setHeart3] = useState(false);
    const [heart4, setHeart4] = useState(false);

    const heart1Clicked = (boolean) => {
        setHeart1(!heart1);
        const url =
            "https://zpk3b603vl.execute-api.eu-west-2.amazonaws.com/v1/feature";
        axios.put(
            url,
            {
                flag: boolean,
                feature: "switch_GP",
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
    };
    const heart2Clicked = (boolean) => {
        setHeart2(!heart2);
        const url =
            "https://zpk3b603vl.execute-api.eu-west-2.amazonaws.com/v1/feature";
        axios.put(
            url,
            {
                flag: boolean,
                feature: "family_profile",
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
    };
    const heart3Clicked = (boolean) => {
        setHeart3(!heart3);
        const url =
            "https://zpk3b603vl.execute-api.eu-west-2.amazonaws.com/v1/feature";
        axios.put(
            url,
            {
                flag: boolean,
                feature: "contraception_plan",
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
    };
    const heart4Clicked = (boolean) => {
        setHeart4(!heart4);
        const url =
            "https://zpk3b603vl.execute-api.eu-west-2.amazonaws.com/v1/feature";
        axios.put(
            url,
            {
                flag: boolean,
                feature: "stress_management",
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
    };

    const phoneScenes = [
        {
            from: 1,
            of: 3,
            image: imgPhone1,
            bgimage: imgHowDoes1,
            title: "Easy onboarding",
            subtitle: "All you need is a verified phone number and your first name.",
        },
        {
            from: 2,
            of: 3,
            image: imgPhone2,
            bgimage: imgHowDoes2,
            title: "Check your cardiovascular health",
            subtitle: "Extract rich health data by simply taking a selfie.",
        },
        {
            from: 3,
            of: 3,
            image: imgPhone3,
            bgimage: imgHowDoes3,
            title: "Get actionable insights",
            subtitle: "Gain insights into your health conditions and treatments.",
        },
    ];

    const reviews = [
        {
            bg: bgCard1,
            content:
                "Very cool and accessible application of computer vision - congrats on the launch!",
            pict: imgDanielePackard,
            name: "Daniele Packard",
            subname: "Co-Founder @ cloudthread.io, Builder",
            link: "https://www.producthunt.com/@daniele_packard",
        },
        {
            bg: bgCard2,
            content:
                "Wow I can't believe it can do all of that just by looking at a photo of you😳🔥!",
            pict: imgJackDavis,
            name: "Jack Davis",
            subname: "Founder of Trnd",
            link: "https://www.producthunt.com/@jack_davis7",
        },
        {
            bg: bgCard3,
            content:
                "Ha really really nice design ! Looks awesome, congrats you guys.",
            pict: imgRobiulIslam,
            name: "Robiul Islam",
            subname: "SEO Professional/Digital Marketer",
            link: "https://www.producthunt.com/@robiulislam10",
        },
        {
            bg: bgCard1,
            content: "Interesting concept and application of CV, will give it a try!",
            pict: imgJessicaChu,
            name: "Jessica Chu",
            subname: "Co-founder & COO of DIG Labs",
            link: "https://www.producthunt.com/@jess_chu",
        },
        {
            bg: bgCard2,
            content:
                "My first impression - very sticky experience when testing the vitals. I didn't verify the results, but... they seem reasonable.",
            pict: imgRickMcCartney,
            name: "Rick McCartney",
            subname: "Vilua Healthcare GmbH",
            link: "https://www.producthunt.com/@rick_mccartney",
        },
        {
            bg: bgCard3,
            content:
                "I am waiting on the final version of your app. It would be great if you just use it and won't have to go to the doctor. Because you will know everything about your health automatically.",
            pict: imgKamil,
            name: "Kamil Galdzicki",
            subname: "Entrepreneur, Manager, IT and E-Commerce",
            link: "https://www.startupschool.org/users/DtkX4JIbf",
        },
        {
            bg: bgCard1,
            content: "I am completely blown away by this technology!!",
            pict: imgAlexis,
            name: "Alexis Abayomi",
            subname: "Founder at UCYCLESYNC LTD",
            link: "https://www.startupschool.org/companies/7xSQCNMhq1r-MA",
        },
        {
            bg: bgCard2,
            content: "Sounds like a game-changing tech",

            pict: imgReetesh,
            name: "Reetesh Aanand",
            subname: "Admere Selvyn Private Limited",
            link: "https://www.linkedin.com/in/reeteshaanand/",
        },
        {
            bg: bgCard3,
            content:
                "I train often and already use monitoring tools but I would definetely use this in my training. Where can I sign up?",
            pict: imgTimur,
            name: "Timur Bidzhiev",
            subname: "CEO at DemoDay",
            link: "https://www.startupschool.org/companies/pFY1gZ28baVVuA",
        },
        {
            bg: bgCard1,
            content: "Great emerging tech in the health field!",
            pict: imgGleb,
            name: "Gleb Galkin",
            subname: "Senior Software Engineer at GROPYUS",
            link: "https://www.linkedin.com/in/artdaw/",
        },
        {
            bg: bgCard2,
            content:
                "Great app! Checked my vitals using DocMe and a pulse oximeter with one unit apart accuracy. Looking forward to the blood pressure module.",
            pict: imgProfile,
            name: "sally12982",
            subname: "22/03/2021",
            link: "https://apps.apple.com/gb/app/docme/id1516996141",
        },
        {
            bg: bgCard3,
            content:
                "It’s a great app! Interesting tech, very useful. Highly recommend usage.",
            pict: imgProfile,
            name: "Vaidehi",
            subname: "25/03/2021",
            link: "https://apps.apple.com/gb/app/docme/id1516996141",
        },
        {
            bg: bgCard1,
            content:
                "Super cool app! Quick and smooth measurements! Loved the insights on my health too :)",
            pict: imgProfile,
            name: "mial918",
            subname: "25/03/2021",
            link: "https://apps.apple.com/gb/app/docme/id1516996141",
        },
    ];

    const [screenNum, setScreenNum] = useState(0);
    const [fadeIn, setFadeIn] = useState("");

    const sendData = () => {
        const url =
            "https://9ml6mvld53.execute-api.eu-west-2.amazonaws.com/v1/subscriber";
        const countryCode = `+` + parsePhoneNumber(phNum).countryCallingCode;
        const phoneNumber = phNum.replace(countryCode, "");
        const data = {
            mobilenumber: phoneNumber,
            countrycode: countryCode,
        };
        // console.log(data)
        axios
            .post(url, data, {
                mode: "cors",
                headers: {
                    Accept: "text/plain",
                    "Content-Type": "application/json",
                },
            })
            .then((res) => console.log(res.status), setSent(true))
            .catch((err) => console.log("Not successfull", err));
    };

    useEffect(() => {
        phoneTransition(".triggerTransition", ".targetImg", ".targetImage");
    }, []);

    const mobileScreen = window.innerWidth < 475 ? true : false;

    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: mobileScreen ? 2 : 3,
        slidesToScroll: mobileScreen ? 2 : 3,
        autoplay: true,
        autoplaySpeed: 400000,
    };

    return (
        <>
            <MetaTags>
                <meta
                    name="description"
                    content="Your heart is a complex system. The DocMe app helps make understanding it a bit simpler. With it, you can measure, monitor and manage hypertension and access a vast array of tailored and personalised health material — and sort through it all with ease."
                />
            </MetaTags>

            <NavbarDocMe prop="docme"/>

            <ContentHeader
                bgColor={!global.mobileView && "#F0F0F0"}
                display="flex"
                t_flexDirection="row-reverse"
                flexDirection="column"
                pt="15vw"
                xs_pt="5vw"
                t_pt="10vw"
                sl_pt="5vw"
                l_pt="2vw"
                t_pb="2vw"
            >
                <Content
                    position="relative"
                    t_bottom="-2.1vw"
                    // mb="10vw"
                    // t_mb="0vw"
                    t_ml="-27vw"
                    pt="20vw"
                    t_pt="0vw"
                    bgColor={global.mobileView && "#F7F8F9"}
                >
                    <Content
                        display={global.mobileView ? "none" : "flex"}
                        position="relative"
                        t_position="absolute"
                        w="100%"
                        justifyContent="flex-end"
                    >
                        <a
                            href="https://www.producthunt.com/newsletter/8114"
                            target="_blank"
                        >
                            <Content
                                t_w="15vw"
                                l_w="10vw"
                                p="2vw"
                                t_px="3vw"
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                            >
                                <Image
                                    src={imgBadge}
                                    alt="DocMe - Your health, from a selfie | Product Hunt"
                                    h="10vw"
                                    t_h="2.8vw"
                                />
                                <Image
                                    src={imgProductHuntUpvote}
                                    alt="DocMe - Your health, from a selfie | Product Hunt Upvote"
                                    h="15vw"
                                    t_h="4.2vw"
                                    mt="4vw"
                                    t_mt="1vw"
                                />
                            </Content>
                        </a>
                    </Content>

                    {global.mobileView ? (
                        <Image
                            src={imgBgMobile}
                            w="99vw"
                            opacity="1"
                            t_w="46vw"
                            sl_w="40vw"
                            l_w="43vw"
                            t_opacity="1"
                            loading="lazy"
                        />
                    ) : (
                        <Image
                            src={imgBg}
                            w="85vw"
                            opacity="1"
                            t_w="46vw"
                            sl_w="40vw"
                            l_w="40vw"
                            t_opacity="1"
                            loading="lazy"
                        />
                    )}
                </Content>

                <Content
                    w="82vw"
                    pt="8vw"
                    pb="10vw"
                    t_pb="0vw"
                    t_pt="7vw"
                    display="flex"
                    flexDirection="column"
                    t_ml="10vw"
                    t_mb="0"
                >
                    {global.mobileView ? (
                        <>
                            <Label
                                xBold
                                fontColor="#001242"
                                w="90vw"
                                t_w="40vw"
                                size="8vw"
                                t_size="3vw"
                            >
                                Your heart matters
                            </Label>
                        </>
                    ) : (
                        <>
                            <Label
                                xBold
                                fontColor="#053FFF"
                                w="90vw"
                                t_w="43vw"
                                size="9.5vw"
                                t_size="3vw"
                                t_mb="2vw"
                            >
                                Your heart matters.
                                <sup style={{ color: "#000000B1" }}>
                                    <Label t_size="0.8vw" size="2.5vw" bolder>
                                        1
                                    </Label>
                                </sup>
                            </Label>
                        </>
                    )}

                    <Content mb="2vw" t_mb="1.3vw">
                        <Label
                            medium
                            size="5vw"
                            t_size="20px"
                            mt="1vw"
                            t_mt="0vw"
                            fontColor="#667093"
                        >
                            Our Wellness plan is free, for life.{" "}
                            <Link
                                href="#wellnessPlan"
                                medium
                                fontColor="#0038FF"
                                size="5.1vw"
                                t_size="20px"
                                display="inline-flex"
                                alignItems="center"
                                t_alignItems="flex-end"
                                ml="20px"
                            >
                                Learn More
                                <Image
                                    w="5.5vw"
                                    t_w="1.5vw"
                                    pl="1vw"
                                    t_pl="0vw"
                                    src={imgArrowRightBlue}
                                />
                            </Link>
                        </Label>
                    </Content>

                    <Label
                        fontColor="#667093"
                        medium
                        w="80vw"
                        mb="6"
                        mt="4vw"
                        t_mt="0vw"
                        t_w="46vw"
                        size="5vw"
                        t_size="20px"
                        display={global.mobileView ? "none" : "block"}
                    >
                        Your heart is a complex system. The DocMe app helps make 
                        understanding it a bit simpler. With it, you can measure, monitor and 
                        manage your cardiovascular health and access a vast array of tailored and personalised 
                        health material — and sort through it all with ease.
                    </Label>
                    {!sent && (
                        <Label
                            medium
                            fontColor="#667093"
                            w="80vw"
                            t_w="46vw"
                            size="3.75vw"
                            t_size="19px"
                            display={global.mobileView ? "none" : "block"}
                        >
                            Enter your cell phone number to join the DocMe experience.
                        </Label>
                    )}

                    {!sent && (
                        <Content
                            className="DocMe"
                            t_w="20vw"
                            display="flex"
                            flexDirection="column"
                            justifyContent="space-between"
                            mt="6vw"
                            mb="7vw"
                            t_mt="0.6vw"
                            t_mb="0.5vw"
                        >
                            <PhoneInput
                                placeholder="Mobile Number"
                                className={global.mobileView ? "iBetaDocme" : "iBeta"}
                                international
                                defaultCountry="GB"
                                onChange={setPhNum}
                            />
                            <Button
                                onClick={sendData}
                                t_my="1vw"
                                bgColor="#053FFF"
                                w="82vw"
                                t_w="50vw"
                                h="13vw"
                                t_w="20vw"
                                t_h="3vw"
                                borderRadius="15px"
                            >
                                <Label
                                    bolder
                                    fontColor="white"
                                    size="4vw"
                                    t_size="1vw"
                                    cursor="pointer"
                                >
                                    Register interest
                                </Label>
                            </Button>
                        </Content>
                    )}

                    {sent && (
                        <Content t_w="36vw" w="70vw">
                            <Label fontColor="#000000B1" size="3.75vw" t_size="1.1vw">
                                <span style={{fontWeight: "600"}}>
                                    A text message has been sent to {phNum}.
                                </span>
                                <br/>
                                We look forward to you enjoying our app!
                            </Label>

                            <Button
                                t_mt="1.3vw"
                                t_mb="1vw"
                                my="7vw"
                                bgColor="#0038FF"
                                w="82vw"
                                t_w="50vw"
                                h="10vw"
                                t_w="20vw"
                                t_h="3vw"
                                display="flex"
                                alignItems="center"
                            >
                                <Image
                                    t_w="1.3vw"
                                    w="5vw"
                                    t_mr="1.5vw"
                                    mr="4vw"
                                    src={imgIconBack}
                                />
                                <Label
                                    onClick={() => setSent(false)}
                                    bold
                                    fontColor="white"
                                    size="4vw"
                                    t_size="1.1vw"
                                    cursor="pointer"
                                >
                                    Enter another number
                                </Label>
                            </Button>
                        </Content>
                    )}

                    <Content
                        t_w="20vw"
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                    >
                        <Image
                            w="auto"
                            h="13vw"
                            t_h="3.2vw"
                            style={{cursor: "pointer"}}
                            onClick={(e) => {
                                e.preventDefault();
                                window.location.href =
                                    "https://apps.apple.com/gb/app/docme/id1516996141";
                            }}
                            src={imgAppStore}
                        />
                        <Label t_size="0.8vw" size="2vw" medium mt="0.5vw"
                            style={{
                                color: global.mobileView
                                    ? "white"
                                    : "#0038FF",
                            }}>
                            COMING SOON
                        </Label>

                        {/*<Image src={imgGooglePlay} w="auto" h="13vw" t_h="3.2vw" t_ml="0.5vw" ml="2vw"*/}
                        {/*       style={{cursor: "pointer"}} onClick={e => {*/}
                        {/*    e.preventDefault()*/}
                        {/*    window.location.href = "https://play.google.com/store/apps/details?id=com.perfexia_docme"*/}
                        {/*}}/>*/}
                    </Content>
                </Content>
            </ContentHeader>

            <Content
                className="docmePartnerSlider"
                w="100%"
                t_mb="6vw"
                mb="15vw"
                pt="8vw"
                t_pt="0"
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Label
                    bold
                    size="6vw"
                    t_size="2.7vw"
                    pb="8vw"                    
                    t_py="4vw"
                    fontColor="#667093"
                    px="10vw"
                    pl="10vw"
                >
                    We have world-class support
                </Label>
                <SliderSlick
                    className="docmeSliderSlick"
                    style={{ width: "85vw", margin: "0 auto" }}
                    {...settings}
                >
                    <div>
                        <Image src={imgP_NHS} t_w="80%"/>
                    </div>
                    <div>
                        <Image src={imgP_Cam} t_w="80%"/>
                    </div>
                    <div>
                        <Image src={imgP_Kings} t_w="80%"/>
                    </div>
                    <div>
                        <Image src={imgP_Dell} t_w="80%"/>
                    </div>                    
                    <div>
                        <Image src={imgP_Pan} t_w="80%"/>
                    </div>
                    <div>
                        <Image src={imgP_plug_play} t_w="55%" my="30px"/>
                    </div> 
                    
                    <div>
                        <Image src={imgP_google} t_w="40%" my="28px"/>
                    </div>                   
                    <div>
                        <Image src={imgP_innovate} t_w="60%" my="18px"/>
                    </div>
                                      
                    <div>
                        <Image src={imgP_nvidia} t_w="25%" my="18px" />
                    </div>
                    <div>
                        <Image src={imgP_europ_union} t_w="80%" t_my="-32px"/>
                    </div>
                    <div>
                        <Image src={imgP_NHSMAA} t_w="80%"/>
                    </div>
                    <div>
                        <Image src={imgP_NIHR} t_w="80%"/>
                    </div>                    
                </SliderSlick>
            </Content>

            <Content
                w="100vw"
                t_mb="0.5vw"
                display="flex"
                flexDirection="column"
                alignItems="center"
                pt="15vw"
                t_pt="0"
            >
                <Label
                    bold
                    align="center"
                    w="90vw"
                    size="9vw"
                    py="10vw"
                    t_w="80vw"
                    t_size="2.5vw"
                    t_py="7vw"
                    t_pb="4vw"
                >
                    Your health and wellbeing, at your fingertips
                </Label>
                <AssesmentCard/>
            </Content>

            <Content
                w="100vw"
                mb="1vw"
                t_mb="0.5vw"
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Label
                    bolder
                    align="center"
                    w="90vw"
                    size="9vw"
                    t_w="45vw"
                    t_size="3.8vw"
                    pt="0vw"
                    pb="4vw"
                >
                    You take the selfie,<br/>
                    we do the rest.
                </Label>
                <Content
                    bgColor="#F0F0F0"
                    w="100vw"
                    display="flex"
                    flexDirection="column"
                    t_flexDirection="row"
                    t_justifyContent="flex-start"
                    t_alignItems="center"
                    t_h="35vw"
                >
                    <Content
                        px="6vw"
                        t_px="0vw"
                        t_pl="16vw"
                        display="flex"
                        flexDirection="column"
                    >
                        <Label
                            bold
                            w="50vw"
                            pt="6vh"
                            t_pt="0vh"
                            t_w="20vw"
                            mb="4vw"
                            t_mb="1.5vw"
                            size="6vw"
                            t_size="2.7vw"
                        >
                            Vital signs
                            <sup style={{color: "#667093"}}>
                                <Label t_size="0.8vw" size="2.5vw" bolder>
                                    2
                                </Label>
                            </sup>
                            , from a selfie.
                        </Label>
                        <Label
                            medium
                            w="80vw"
                            t_w="41vw"
                            size="5.5vw"
                            t_size="1.6vw"
                            pb="6vh"
                            t_pb="0vh"
                            fontColor="#000000B1"
                        >
                            DocMe uses advanced machine learning to determine the things that matter most to you, like
                            your vital signs such as heart rate, heart rate variability, respiratory rate and blood
                            pressure.
                        </Label>
                    </Content>
                    <Image
                        src={pfa}
                        t_pl="3vw"
                        w="100vw"
                        t_w="24vw"
                        loading="lazy"
                        ml="2.5vw"
                    />
                </Content>
            </Content>

            <Content
                px="6vw"
                t_px="0vw"
                bgColor="#F0F0F0"
                w="100vw"
                mb="1vw"
                t_mb="0.5vw"
                display="flex"
                flexDirection="column"
                t_flexDirection="row"
                t_justifyContent="flex-start"
                t_alignItems="center"
            >
                <Content
                    display="none"
                    t_display="flex"
                    t_justifyContent="flex-start"
                    t_alignItems="center"
                    t_h="38vw"
                >
                    <Image
                        src={imgGetActionableInsights}
                        loading="lazy"
                        t_pl="12vw"
                        w="28vw"
                    />
                    <Content t_pl="15vw" display="flex" flexDirection="column">
                        {/* <Label
                            fontColor="#667093"
                            bolder
                            w="80vw"
                            t_w="30vw"
                            mb="3vw"
                            t_mb="1vw"
                            t_size="0.8vw"
                            size="2.5vw"
                        >
                            COMING SOON
                        </Label> */}
                        <Label
                            bold
                            w="80vw"
                            t_w="30vw"
                            mb="4vw"
                            t_mb="1.5vw"
                            size="6vw"
                            t_size="2.8vw"
                        >
                            A comprehensive view of you.
                        </Label>
                        <Label
                            medium
                            w="80vw"
                            t_w="32vw"
                            size="5.5vw"
                            t_size="1.3vw"
                            pb="6vh"
                            t_pb="0vh"
                            fontColor="#000000B1"
                        >
                            Quickly view your health trends over time and dive in for a deeper
                            look to see how your vital signs have changed over a day, week or
                            month.
                        </Label>
                    </Content>
                </Content>

                <Content display="block" t_display="none">
                    <Content t_pl="3vw" display="flex" flexDirection="column">
                        {/* <Label
                            fontColor="#667093"
                            bolder
                            w="80vw"
                            pt="6vh"
                            t_w="30vw"
                            mb="3vw"
                            t_mb="1vw"
                            t_size="0.8vw"
                            size="2.5vw"
                        >
                            COMING SOON
                        </Label> */}
                        <Label
                            bolder
                            w="69vw"
                            pt="6vh"
                            t_pt="0vh"
                            t_w="30vw"
                            mb="4vw"
                            t_mb="1.5vw"
                            size="6vw"
                            t_size="2.8vw"
                        >
                            A comprehensive view of you.
                        </Label>
                        <Label
                            normal
                            w="80vw"
                            t_w="36vw"
                            size="5.5vw"
                            t_size="1.7vw"
                            pb="4vh"
                            t_pb="0vh"
                            fontColor="#000000B1"
                        >
                            Quickly view your health trends over time and dive in for a deeper
                            look to see how your vital signs have changed over a day, week or
                            month.
                        </Label>
                    </Content>
                    <Image
                        src={imgGetActionableInsights}
                        loading="lazy"
                        t_pl="10vw"
                        w="96vw"
                        t_w="28vw"
                    />
                </Content>
            </Content>

            <Content
                w="100vw"
                mb="1vw"
                t_mb="0.5vw"
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Content
                    bgColor="#F0F0F0"
                    w="100vw"
                    display="flex"
                    flexDirection="column"
                    t_flexDirection="row"
                    t_justifyContent="flex-start"
                    t_alignItems="center"
                    t_h="38vw"
                >
                    <Content
                        px="6vw"
                        t_px="0vw"
                        t_pl="15vw"
                        display="flex"
                        flexDirection="column"
                    >
                        <Label
                            bold
                            w="70vw"
                            t_w="28vw"
                            pt="6vh"
                            t_pt="0vh"
                            mb="4vw"
                            t_mb="1.5vw"
                            size="6vw"
                            t_size="2.8vw"
                        >
                            Track what is important to you.
                        </Label>
                        <Label
                            medium
                            w="80vw"
                            t_w="32vw"
                            size="5.5vw"
                            t_size="1.3vw"
                            pb="6vh"
                            t_pb="0vh"
                            fontColor="#000000B1"
                        >
                            DocMe consolidates data from compatible third-party health apps you already use, providing a
                            source of information that’s more objective than ever before.
                        </Label>
                    </Content>
                    <Image
                        src={imgTrackImportant}
                        t_pl="8vw"
                        w="100vw"
                        t_w="31vw"
                        loading="lazy"
                    />
                </Content>
            </Content>

            <Content
                w="100vw"
                mb="1vw"
                t_mb="0.5vw"
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Content
                    bgColor="#F0F0F0"
                    w="100vw"
                    display="flex"
                    flexDirection="column"
                    t_flexDirection="row"
                    t_justifyContent="flex-start"
                    t_alignItems="center"
                >
                    <Image
                        src={imgReceiveInsights}
                        t_w="24vw"
                        loading="lazy"
                        t_pl="15vw"
                    />
                    <Content
                        px="6vw"
                        t_px="0vw"
                        t_pl="15vw"
                        display="flex"
                        flexDirection="column"
                    >
                        <Label
                            bold
                            w="70vw"
                            t_w="28vw"
                            pt="6vh"
                            t_pt="0vh"
                            mb="4vw"
                            t_mb="1.5vw"
                            size="6vw"
                            t_size="2.8vw"
                        >
                            And receive insights to take action.
                        </Label>
                        <Label
                            medium
                            w="80vw"
                            t_w="32vw"
                            size="5.5vw"
                            t_size="1.3vw"
                            pb="6vh"
                            t_pb="0vh"
                            fontColor="#000000B1"
                        >
                            The DocMe app helps you gain insights into your health conditions and treatments through personalised, actionable insights and offer contextually appropriate health advice.
                        </Label>
                    </Content>
                </Content>
            </Content>


            <Content
                px="6vw"
                t_px="0vw"
                bgColor="#F0F0F0"
                w="100vw"
                mb="1vw"
                t_mb="0.5vw"
                display="flex"
                flexDirection="column"
                t_flexDirection="row"
                t_justifyContent="flex-start"
                t_alignItems="center"
            >
                <Content
                    display="none"
                    t_display="flex"
                    t_justifyContent="flex-start"
                    t_alignItems="center"
                    t_h="38vw"
                >
                    <Content
                        px="6vw"
                        t_px="0vw"
                        t_pl="15vw"
                        display="flex"
                        flexDirection="column"
                    >
                        <Label
                            bold
                            w="80vw"
                            mb="4vw"
                            t_mb="1.5vw"
                            size="6vw"
                            t_size="2.8vw"
                            t_w="28vw"
                        >
                            The more you learn, the fitter you become.
                        </Label>
                        <Label
                            medium
                            w="80vw"
                            t_w="32vw"
                            size="5.5vw"
                            t_size="1.3vw"
                            pb="6vh"
                            t_pb="0vh"
                            fontColor="#000000B1"
                        >
                            You can view all your progress in one convenient place, see your long-term trends, or learn
                            more about how to improve your fitness levels from a wide range of health metrics.
                        </Label>
                    </Content>
                    <Image src={imgTheMoreYouLearn} loading="lazy" t_pl="12vw" w="35vw" />
                </Content>

                <Content display="block" t_display="none">
                    <Content t_pl="3vw" display="flex" flexDirection="column">
                        <Label
                            bolder
                            w="69vw"
                            pt="6vh"
                            t_pt="0vh"
                            t_w="30vw"
                            mb="4vw"
                            t_mb="1.5vw"
                            size="6vw"
                            t_size="2.8vw"
                        >
                            The more you learn, the fitter you become.
                        </Label>
                        <Label
                            normal
                            w="80vw"
                            t_w="36vw"
                            size="5.5vw"
                            t_size="1.7vw"
                            pb="4vh"
                            t_pb="0vh"
                            fontColor="#000000B1"
                        >
                            You can view all your progress in one convenient place, see your
                            long-term trends, or learn more about how to improve your fitness
                            levels from a wide range of health metrics.
                        </Label>
                    </Content>
                    <Image
                        src={imgTheMoreYouLearn}
                        loading="lazy"
                        t_pl="10vw"
                        w="96vw"
                        t_w="35vw"
                    />
                </Content>
            </Content>

            <Content
                w="100vw"
                mb="1vw"
                t_mb="0.5vw"
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Content
                    bgColor="#F0F0F0"
                    w="100vw"
                    display="flex"
                    flexDirection="column"
                    t_flexDirection="row"
                    t_justifyContent="flex-start"
                    t_alignItems="center"
                    t_h="38vw"
                >
                    
                    <Image
                        src={imgDocmeYourData}
                        t_pl="5vw"
                        w="100vw"
                        t_w="34vw"
                        loading="lazy"
                        mb="6vw"
                        t_mb="0vw"
                    />
                    <Content
                        px="6vw"
                        t_px="0vw"
                        t_pl="15vw"
                        display="flex"
                        flexDirection="column"
                    >
                        <Label
                            bold
                            w="60vw"
                            t_w="28vw"
                            pt="6vh"
                            t_pt="0vh"
                            mb="4vw"
                            t_mb="1.5vw"
                            size="6vw"
                            t_size="2.8vw"
                        >
                            We’re committed to protecting your data.
                        </Label>
                        <Label
                            medium
                            w="80vw"
                            t_w="31vw"
                            size="5.5vw"
                            t_size="1.3vw"
                            pb="6vh"
                            t_pb="0vh"
                            fontColor="#000000B1"
                        >
                            DocMe prioritizes privacy with advanced technologies and security measures, ensuring limited access to your data. We continuously enhance data safety and employ robust security features for your exclusive access. Video processing on your device with DocMe algorithms guarantees high-quality results similar to server-based models with enhanced privacy protection.
                        </Label>
                    </Content>
                </Content>
            </Content>

            <Content h="10vw" w="49vw" mt="2.75vw" m="auto">
                <Label t_size="3.40vw" bold align="center">
                    {global.mobileView ? "" : "How the DocMe app works."}
                </Label>
            </Content>
            <Content display="none" t_display="block">
                <Content
                    w="100vw"
                    position="relative"
                    display="flex"
                    t_flexDirection="row"
                >
                    <Content
                        w="50vw"
                        bgColor="#F0F5F7"
                        mr="0.5vw"
                        id={!global.mobileView ? "howDocmeWorks" : "none"}
                        style={{scrollMarginTop: "80px"}}
                    >
                        <CarouselProvider
                            infinite
                            naturalSlideWidth={50}
                            naturalSlideHeight={40}
                            totalSlides={3}
                            visibleSlides={1}
                            style={{ padding: "0px" }}
                        >
                            <Slider classNameAnimation="CarouselAnimation">
                                <Slide
                                    className={fadeIn}
                                    index={0}
                                    style={{paddingBottom: "28%"}}
                                >
                                    <Image
                                        style={{
                                            width: "18vw",
                                            marginLeft: "calc(100% -  35vw)",
                                            marginTop: "calc(100% - 46vw)",
                                        }}
                                        src={imgPhone1}
                                        loading="lazy"
                                    ></Image>
                                </Slide>
                                <Slide
                                    className={fadeIn}
                                    index={1}
                                    style={{ paddingBottom: "28%" }}
                                >
                                    <Image
                                        style={{
                                            width: "18vw",
                                            marginLeft: "calc(100% -  35vw)",
                                            marginTop: "calc(100% - 46vw)",
                                        }}
                                        src={imgPhone2}
                                        loading="lazy"
                                    ></Image>
                                </Slide>
                                <Slide
                                    className={fadeIn}
                                    index={2}
                                    style={{ paddingBottom: "28%" }}
                                >
                                    <Image
                                        style={{
                                            width: "18vw",
                                            marginLeft: "calc(100% -  35vw)",
                                            marginTop: "calc(100% - 46vw)",
                                        }}
                                        src={imgPhone3}
                                        loading="lazy"
                                    ></Image>
                                </Slide>
                            </Slider>
                            <Content position="relative" left="80vw">
                                <ButtonBack
                                    onClick={() => {
                                        setScreenNum(screenNum === 0 ? 2 : screenNum - 1);
                                        setFadeIn("CarouselSlide");
                                        setTimeout(() => setFadeIn(""), 1000);
                                    }}
                                    style={{
                                        outline: "none",
                                        border: "none",
                                        backgroundColor: "transparent",
                                        marginTop:"40px"
                                    }}
                                >
                                    <Image
                                        src={imgCarouselLeft}
                                        style={{width: "4.8vw", border: "none"}}
                                    />
                                </ButtonBack>
                                <ButtonNext
                                    onClick={() => {
                                        setScreenNum((screenNum + 1) % 3);
                                        setFadeIn("CarouselSlide");
                                        setTimeout(() => setFadeIn(""), 1000);
                                    }}
                                    style={{
                                        outline: "none",
                                        border: "none",
                                        backgroundColor: "transparent",
                                    }}
                                >
                                    <Image
                                        src={imgCarouselRight}
                                        style={{width: "4.8vw", border: "none"}}
                                    />
                                </ButtonNext>
                            </Content>
                        </CarouselProvider>
                    </Content>
                    <Content
                        w="50vw"
                        display="flex"
                        flexDirection="column"
                        py="10vw"
                        alignItems="center"
                        bgImage={`url(${phoneScenes[screenNum].bgimage})`}
                    >
                        <Label
                            t_w="30vw"
                            fontColor="#000000B1"
                            bolder
                            t_size="2.9vw"
                            t_mx="auto"
                            pt="2vw"
                        >
                            <span style={{ color: "#000000", fontWeight: "600" }}>How does DocMe app work?</span>
                        </Label>
                        <Label t_w="30vw" bold t_size="2.5vw" t_mx="auto" pt="5vw">
                            {phoneScenes[screenNum].from}
                            <span style={{ color: "#667093", fontWeight: "500" }}>/3</span>
                        </Label>
                        <Label
                            t_w="30vw"
                            color="#000000B1"
                            medium
                            t_size="1vw"
                            t_mx="auto"
                            pt="0.2vw"
                        >
                            {phoneScenes[screenNum].from == "3" ? "COMING SOON" : ""}
                        </Label>
                        <Label
                            t_w="30vw"
                            fontColor="#000000B1"
                            bolder
                            t_size="2.7vw"
                            t_mx="auto"
                            pt="2vw"
                        >
                            {phoneScenes[screenNum].title}
                        </Label>
                        <Label
                            t_w="30vw"
                            medium
                            fontColor="#000000B1"
                            t_size="1.79vw"
                            t_mx="auto"
                            pt="2vw"
                        >
                            {phoneScenes[screenNum].subtitle}
                        </Label>
                    </Content>
                </Content>
            </Content>

            {global.mobileView && (
                <Content
                    w="100vw"
                    t_py="3vw"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    id={global.mobileView ? "howDocmeWorks" : "none"}
                    style={{scrollMarginTop: "65px"}}
                >
                    <Label
                        bold
                        align="center"
                        w="90vw"
                        size="9vw"
                        pt="15vw"
                        pb="15vw"
                        t_w="65vw"
                        t_size="2.8vw"
                        t_pt="3vw"
                        t_pb="6vw"
                    >
                        How DocMe works
                    </Label>
                </Content>
            )}

            <Content display="block" t_display="none">
                <Content display="flex" flexDirection="column">
                    {phoneScenes.map((x, i) => {
                        return (
                            <Content
                                key={i}
                                pt="15vw"
                                display="flex"
                                flexDirection="column"
                                bgColor="#F0F5F7"
                            >
                                <Image src={x.image} w="80vw" mx="auto" loading="lazy"/>
                                <Content
                                    w="90vw"
                                    py="10vw"
                                    px="10vw"
                                    display="flex"
                                    flexDirection="column"
                                    bgColor="white"
                                >
                                    <Label size="5vw">
                                        <b>{x.from}</b>/{x.of}
                                    </Label>
                                    <Label
                                        t_w="27vw"
                                        color="#000000B1"
                                        medium
                                        size="3.5vw"
                                        t_mx="auto"
                                        pt="1vw"
                                    >
                                        {x.from == "4" ? "COMING SOON" : ""}
                                    </Label>
                                    <Label bold size="5vw" py="3vw">
                                        {x.title}
                                    </Label>
                                    <Label medium w="80vw" size="5.5vw">
                                        {x.subtitle}
                                    </Label>
                                </Content>
                            </Content>
                        );
                    })}
                </Content>
            </Content>

            <Content
                w="100vw"
                t_py="4vw"
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Label
                    bold
                    align="center"
                    w="75vw"
                    size="7vw"
                    pt="15vw"
                    pb="6vw"
                    t_w="65vw"
                    t_size="1.8vw"
                    t_py="3vw"
                >
                    What people say about us
                </Label>

                <FeedbackBox
                    display="flex"
                    flexDirection="row"
                    w="100vw"
                    t_w="75%"
                    t_mb="4vw"
                    t_h="15vw"
                    mx="0vw"
                    t_mx="auto"
                    pl="-10vw"
                    mt="5vw"
                    mb="15vw"
                    t_mt="0vw"
                >
                    <Content
                        style={{
                            // width: "100%",
                            display: "flex",
                            width: window.innerWidth > window.innerHeight ? "82vw" : "100vw",
                        }}
                    >
                        {reviews.map((data, i) => (
                            <Content
                                key={i.toString()}
                                display="flex"
                                flexDirection="column"
                                justifyContent="space-between"
                                minW="69vw"
                                t_minW="16vw"
                                mx="2vw"
                                t_mx="0.25vw"
                                pl="5vw"
                                t_pl="2vw"
                                pt="1vw"
                                t_pt="0.2vw"
                                pb="4vw"
                                t_pb="1.5vw"
                                pr="1.25vw"
                                t_pr="0.5vw"
                                bgImage={`url(${data.bg})`}
                            >
                                <Content
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    h="100%"
                                >
                                    <Label
                                        bolder
                                        fontStyle="italic"
                                        lineHeight="9vw"
                                        t_lineHeight="1vw"
                                        size="10vw"
                                        t_size="3vw"
                                        mt="2vw"
                                        t_mt="1vw"
                                    >
                                        ''
                                    </Label>
                                    <Label
                                        bolder
                                        size="3vw"
                                        t_size="0.9vw"
                                        mr="4.5vw"
                                        t_mr="2vw"
                                        t_ml="0.3vw"
                                        t_mt="0.5vw"
                                        mt="1vw"
                                        fontColor="#000000"
                                    >
                                        {data.content}
                                    </Label>
                                </Content>
                                <a
                                    href={data.link}
                                    target="_blank"
                                    style={{textDecoration: "none"}}
                                >
                                    <Content display="flex" alignItems="flex-start">
                                        <Image
                                            src={data.pict}
                                            mr="4vw"
                                            t_mr="1.2vw"
                                            h="9vw"
                                            t_h="2.2vw"
                                            borderRadius="50%"
                                        />
                                        <Content display="flex" flexDirection="column">
                                            <Label
                                                bolder
                                                fontColor="black"
                                                size="2.6vw"
                                                t_size="0.85vw"
                                                mb="1vw"
                                                t_mb="0.2vw"
                                            >
                                                {data.name}
                                            </Label>
                                            <Label
                                                medium
                                                fontColor="black"
                                                size="2.3vw"
                                                t_size="0.55vw"
                                            >
                                                {data.subname}
                                            </Label>
                                        </Content>
                                    </Content>
                                </a>
                            </Content>
                        ))}
                    </Content>
                </FeedbackBox>
            </Content>

            <Content id="wellnessPlan" style={{scrollMarginTop: "100px"}}>
                <Plan/>
            </Content>

            {/* <Content
        classname="targetStop"
        mb="10vw"
        t_mb="2vw"
        t_px="10vw"
        t_pb="7vw"
        t_pt="1vw"
        mt="10vw"
        t_mt="0vw"
        display="flex"
        flexDirection="column"
        t_alignItems="flex-start"
      >
        <Content ml="13.5vw" t_ml="0">
          <Label
            bold
            fontColor="grey"
            display="flex"
            flexDirection="row"
            align="center"
            size="3.5vw"
            t_size="1vw"
          >
            LET US KNOW *
          </Label>
        </Content>
        <Content
          display="flex"
          justifyContent="center"
          t_justifyContent="left"
          ml="11vw"
          t_ml="-0.2vw"
        >
          <Label
            bold
            w="85vw"
            t_w="40vw"
            mb="10vw"
            t_mb="4vw"
            size="9vw"
            t_size="2.8vw"
            align="left"
            t_align="left"
          >
            Expect more from DocMe{" "}
            <sup style={{ color: "#667093" }}>
              <Label t_size="0.8vw" size="2.5vw" bolder>
                **
              </Label>
            </sup>
          </Label>
        </Content>
        <Content
          display="flex"
          flexDirection="column"
          t_flexDirection="row"
          ml="13.5vw"
          t_mx="0vw"
        >
          <Content display="flex" flexDirection="column" my="2vh">
            <Link
              href="/docme/more"
              fontColor="black"
              bold
              size="5.5vw"
              t_size="1.12vw"
              t_mb="0.1vw"
            >
              <Content display="flex" flexDirection="row" alignItems="center">
                <Label>Stress Management</Label>
                <Image
                  src={imgArrowRightBlack}
                  w="3.5vw"
                  t_w="1.5vw"
                  t_px="2vw"
                  ml="2vw"
                />
              </Content>
            </Link>

            {!heart4 ? (
              <Content
                onClick={() => heart4Clicked("true")}
                display="flex"
                alignItems="center"
                mt="2vw"
                t_mt="1vw"
                cursor="pointer"
              >
                <Image
                  src={heartOutline}
                  t_h="2.5vw"
                  h="9vw"
                  t_mr="1vw"
                  mr="2vw"
                />
                <Label
                  cursor="pointer"
                  bold
                  fontColor="grey"
                  t_size="0.8vw"
                  size="3.5vw"
                >
                  I WANT IT!
                </Label>
              </Content>
            ) : (
              <Content
                onClick={() => heart4Clicked("false")}
                display="flex"
                alignItems="center"
                mt="2vw"
                t_mt="1vw"
                cursor="pointer"
              >
                <Image
                  src={heartSelected}
                  t_h="2.5vw"
                  h="9vw"
                  t_mr="1vw"
                  mr="2vw"
                />
                <Label
                  cursor="pointer"
                  bold
                  fontColor="grey"
                  t_size="0.8vw"
                  size="3.5vw"
                >
                  STAY TUNED!
                </Label>
              </Content>
            )}
          </Content>
          <Content display="flex" flexDirection="column" my="2vh">
            <Link
              href="/docme/switch-gp"
              fontColor="black"
              bold
              size="5.5vw"
              t_size="1.12vw"
              t_mb="0.1vw"
            >
              <Content display="flex" flexDirection="row" alignItems="center">
                <Label>Switch to a new GP</Label>
                <Image
                  src={imgArrowRightBlack}
                  w="3.5vw"
                  t_w="1.5vw"
                  t_px="2vw"
                  ml="2vw"
                />
              </Content>
            </Link>

            {!heart1 ? (
              <Content
                onClick={() => heart1Clicked("true")}
                display="flex"
                alignItems="center"
                mt="2vw"
                t_mt="1vw"
                cursor="pointer"
              >
                <Image
                  src={heartOutline}
                  t_h="2.5vw"
                  h="9vw"
                  t_mr="1vw"
                  mr="2vw"
                />
                <Label
                  cursor="pointer"
                  bold
                  fontColor="grey"
                  t_size="0.8vw"
                  size="3.5vw"
                >
                  I WANT IT!
                </Label>
              </Content>
            ) : (
              <Content
                onClick={() => heart1Clicked("false")}
                display="flex"
                alignItems="center"
                mt="2vw"
                t_mt="1vw"
                cursor="pointer"
              >
                <Image
                  src={heartSelected}
                  t_h="2.5vw"
                  h="9vw"
                  t_mr="1vw"
                  mr="2vw"
                />
                <Label
                  cursor="pointer"
                  bold
                  fontColor="grey"
                  t_size="0.8vw"
                  size="3.5vw"
                >
                  STAY TUNED!
                </Label>
              </Content>
            )}
          </Content>
          <Content display="flex" flexDirection="column" my="2vh">
            <Link
              href="/docme/family-profiles"
              fontColor="black"
              bold
              size="5.5vw"
              t_size="1.12vw"
              t_mb="0.1vw"
            >
              <Content display="flex" flexDirection="row" alignItems="center">
                <Label>Family Profiles</Label>
                <Image
                  src={imgArrowRightBlack}
                  w="3.5vw"
                  t_w="1.5vw"
                  t_px="2vw"
                  ml="2vw"
                />
              </Content>
            </Link>

            {!heart2 ? (
              <Content
                onClick={() => heart2Clicked("true")}
                display="flex"
                alignItems="center"
                mt="2vw"
                t_mt="1vw"
                cursor="pointer"
              >
                <Image
                  src={heartOutline}
                  t_h="2.5vw"
                  h="9vw"
                  t_mr="1vw"
                  mr="2vw"
                />
                <Label
                  cursor="pointer"
                  bold
                  fontColor="grey"
                  t_size="0.8vw"
                  size="3.5vw"
                >
                  I WANT IT!
                </Label>
              </Content>
            ) : (
              <Content
                onClick={() => heart2Clicked("false")}
                cursor="pointer"
                display="flex"
                alignItems="center"
                mt="2vw"
                t_mt="1vw"
              >
                <Image
                  src={heartSelected}
                  t_h="2.5vw"
                  h="9vw"
                  t_mr="1vw"
                  mr="2vw"
                />
                <Label
                  cursor="pointer"
                  bold
                  fontColor="grey"
                  t_size="0.8vw"
                  size="3.5vw"
                >
                  STAY TUNED!
                </Label>
              </Content>
            )}
          </Content>
          <Content display="flex" flexDirection="column" my="2vh">
            <Link
              href="/docme/contraception-plan"
              fontColor="black"
              bold
              size="5.5vw"
              t_size="1.12vw"
              t_mb="0.1vw"
            >
              <Content display="flex" flexDirection="row" alignItems="center">
                <Label>Contraception Plan</Label>
                <Image
                  src={imgArrowRightBlack}
                  w="3.5vw"
                  t_w="1.5vw"
                  t_px="2vw"
                  ml="2vw"
                />
              </Content>
            </Link>

            {!heart3 ? (
              <Content
                onClick={() => heart3Clicked("true")}
                display="flex"
                alignItems="center"
                mt="2vw"
                t_mt="1vw"
                cursor="pointer"
              >
                <Image
                  src={heartOutline}
                  t_h="2.5vw"
                  h="9vw"
                  t_mr="1vw"
                  mr="2vw"
                />
                <Label
                  cursor="pointer"
                  bold
                  fontColor="grey"
                  t_size="0.8vw"
                  size="3.5vw"
                >
                  I WANT IT!
                </Label>
              </Content>
            ) : (
              <Content
                onClick={() => heart3Clicked("false")}
                cursor="pointer"
                display="flex"
                alignItems="center"
                mt="2vw"
                t_mt="1vw"
              >
                <Image
                  src={heartSelected}
                  t_h="2.5vw"
                  h="9vw"
                  t_mr="1vw"
                  mr="2vw"
                />
                <Label
                  cursor="pointer"
                  bold
                  fontColor="grey"
                  t_size="0.8vw"
                  size="3.5vw"
                >
                  STAY TUNED!
                </Label>
              </Content>
            )}
          </Content>
        </Content>
        <Content
          t_w="50%"
          w="70%"
          display="flex"
          flexDirection="column"
          ml="14%"
          t_ml="0"
          mt="7vw"
          t_mt="3vw"
        >
          <Label
            display="flex"
            fontColor="#667093"
            size="2.5vw"
            t_size="0.8vw"
            mb="5vw"
            t_mb="1vw"
          >
            * We value your helpful feedback . Although our goal is to deliver
            the best solutions to meet your needs, we cannot guarantee that our
            team will be able to prioritise your requests in any given
            timeframe.
          </Label>
          <Label display="flex" fontColor="#667093" size="2.5vw" t_size="0.8vw">
            ** The information provided on this web page is intended for
            informational purposes only and some features may not be applicable
            in your country, region, or city. It is subject to change and may be
            updated without notice.
          </Label>
        </Content>
      </Content> */}


            {/*
            <Content
                w="100vw"
                mb="20vw"
                t_mb="3vw"
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Content
                    bgColor="#EEEEEE"
                    w="100vw"
                    t_w="100vw"
                    display="flex"
                    flexDirection="column"
                >
                    <Content
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        my="7vw"
                        t_mt="7vw"
                        t_mb="0vw"
                    >
                        <Label bolder align="center" size="7vw" t_size="3vw" w="70vw" t_w="35vw">
                            DocMe works with Apple Health
                        </Label>
                        <Content display="flex" flexDirection="row">
                            <Image
                                src={imgMeetDocme}
                                t_h="auto"
                                h="47vw"
                                w="47vw"
                                t_w="16vw"
                                mb="8vw"
                                t_mb="2vw"
                                loading="lazy"
                            />
                            <Image
                                src={imgMeetHealth}
                                t_h="auto"
                                h="47vw"
                                w="47vw"
                                t_w="16vw"
                                mb="8vw"
                                t_mb="2vw"
                                loading="lazy"
                            />
                        </Content>
                        <Content
                            w="90vw"
                            h="35vw"
                            t_h="auto"
                            t_w="43vw"
                            display="flex"
                            flexDirection="column"
                            justifyContent="space-between"
                            t_mb="4vw"
                        >
                            <Label
                                t_w="47vw"
                                normal
                                size="4.5vw"
                                t_size="1.4vw"
                                t_lineHeight="2vw"
                                mb="10vw"
                                t_mb="2vw"
                                ml="4vw"
                                t_ml="0vw"
                            >
                                DocMe makes it easy for you to take control of your health by using
                                your smartphone that’s already in your pocket. And it integrates
                                with the tools you already love.
                            </Label>
                            <Content
                                w="100%"
                                display="flex"
                                justifyContent="flex-end"
                                mt="2vw"
                                t_mt="5vw"
                                mr="6vw"
                                t_mr="2.5vw"
                            >
                                <Link
                                    href="/docme"
                                    bold
                                    fontColor="black"
                                    size="4.5vw"
                                    t_size="1.4vw"
                                    display="flex"
                                    alignItems="center"
                                >
                                    <Label>Learn More</Label>
                                    <Image
                                        src={imgArrowRightBlack}
                                        w="4.5vw"
                                        t_w="1.5vw"
                                        pl="1vw"
                                    />
                                </Link>
                            </Content>
                        </Content>
                    </Content>
                </Content>
            </Content> */}

            <Content
                w="100vw"
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Label
                    bold
                    align="center"
                    w="80vw"
                    size="7vw"
                    py="6vw"
                    t_w="65vw"
                    t_size="1.7vw"
                    t_py="3vw"
                >
                    DocMe protects your health data
                    <sup style={{color: "#667093"}}>
                        <Label t_size="0.8vw" size="2.5vw" bolder>
                            ***
                        </Label>
                    </sup>
                </Label>
                <Content
                    display="flex"
                    flexWrap="wrap"
                    t_flexDirection="row"
                    t_mb="1vw"
                    justifyContent="space-evenly"
                >
                    <Content t_mr="0.5vw">
                        <Link href="https://ico.org.uk/ESDWebPages/Entry/ZA787355">
                            <Image src={icosLogoSection} w="48vw" t_w="13.10vw"/>
                        </Link>
                    </Content>
                    <Content t_mr="0.5vw">
                        <Link href="https://www.dsptoolkit.nhs.uk/OrganisationSearch/YGM1J">
                            <Image src={nhsLogoSection} w="48vw" t_w="13.10vw"/>
                        </Link>
                    </Content>
                    <Content t_mr="0.5vw">
                        <Image src={gdprLogoSection} w="48vw" t_w="13.10vw"/>
                    </Content>
                    <Content t_mr="0.5vw">
                        <Image src={hippaLogoSection} w="48vw" t_w="13.10vw"/>
                    </Content>
                </Content>

                <Content t_w="53%" w="53%" display="flex" mt="5vw" t_mt="0vw">
                    <Label
                        display="flex"
                        fontColor="#667093"
                        size="2.5vw"
                        t_size="0.7vw"
                        mb="5vw"
                        t_mb="4vw"
                    >
                        *** DocMe is designed to protect privacy and give you control over
                        your health data. Your health data is hidden from us, and we cannot
                        see your full facial video. We maximise data security through data
                        minimisation, on-device intelligence, security protections, and by
                        prioritising transparency and control. Finally, DocMe is designed
                        with digital inclusion and diversity in mind so that our technology
                        works on all skin tones and is blind to ethnicity.
                    </Label>
                </Content>
            </Content>

            {/* This section is hidden until further notice */}
            <Content
                w="100vw"
                display="none"
                flexDirection="column"
                alignItems="center"
            >
                <Label
                    bold
                    align="center"
                    w="90vw"
                    size="9vw"
                    py="10vh"
                    t_w="90vw"
                    t_size="2.8vw"
                    t_py="4vw"
                >
                    Our cutting edge technologies
                </Label>
                <Content display="flex" flexDirection="column" t_flexDirection="row">
                    <Content position="relative">
                        <Image src={imgBgCV} w="100%" t_h="22vw"/>
                        <Content
                            display="flex"
                            flexDirection="column"
                            w="45"
                            position="absolute"
                            zIndex="1"
                            top="0"
                            left="0"
                            mt="6vw"
                            ml="6vw"
                            t_mt="2.5vw"
                            t_ml="2.5vw"
                        >
                            <Label
                                bold
                                size="8vw"
                                t_size="2.5vw"
                                fontColor="white"
                                mt="10vw"
                                t_mt="3vw"
                            >
                                Computer Vision
                            </Label>
                        </Content>
                        <LearnMore
                            display="flex"
                            alignItems="center"
                            position="absolute"
                            zIndex="1"
                            bottom="0"
                            right="0"
                            mb="6vw"
                            mr="6vw"
                            t_mb="2.5vw"
                            t_mr="2.5vw"
                        >
                            <Link
                                href="/computer-vision"
                                bold
                                fontColor="white"
                                size="5vw"
                                t_size="1.3vw"
                                decoration="none"
                            >
                                Learn More
                            </Link>
                            <Image src={imgArrowRight} w="4.5vw" t_w="1.5vw" pl="1vw"/>
                        </LearnMore>
                    </Content>
                    <Content position="relative" mx="0.5vw">
                        <Image src={imgBgXAI} w="100%" t_h="22vw"/>
                        <Content
                            display="flex"
                            flexDirection="column"
                            w="45"
                            position="absolute"
                            zIndex="1"
                            top="0"
                            left="0"
                            mt="6vw"
                            ml="6vw"
                            t_mt="2.5vw"
                            t_ml="2.5vw"
                        >
                            <Label
                                bold
                                size="8vw"
                                t_size="2.5vw"
                                fontColor="white"
                                mt="10vw"
                                t_mt="3vw"
                            >
                                Explainable AI (XAI)
                            </Label>
                        </Content>
                        <LearnMore
                            display="flex"
                            alignItems="center"
                            position="absolute"
                            zIndex="1"
                            bottom="0"
                            right="0"
                            mb="6vw"
                            mr="6vw"
                            t_mb="2.5vw"
                            t_mr="2.5vw"
                        >
                            <Link
                                href="/explainable-ai"
                                bold
                                fontColor="white"
                                size="5vw"
                                t_size="1.3vw"
                                decoration="none"
                            >
                                Learn More
                            </Link>
                            <Image src={imgArrowRight} w="4.5vw" t_w="1.5vw" pl="1vw"/>
                        </LearnMore>
                    </Content>
                    <Content position="relative">
                        <Image src={imgBgEML} w="100%" t_h="22vw"/>
                        <Content
                            display="flex"
                            flexDirection="column"
                            w="45"
                            position="absolute"
                            zIndex="1"
                            top="0"
                            left="0"
                            mt="6vw"
                            ml="6vw"
                            t_mt="2.5vw"
                            t_ml="2.5vw"
                        >
                            <Label
                                bold
                                size="8vw"
                                t_size="2.5vw"
                                fontColor="white"
                                mt="10vw"
                                t_mt="3vw"
                            >
                                Edge Machine Learning
                            </Label>
                        </Content>
                        <LearnMore
                            display="flex"
                            alignItems="center"
                            position="absolute"
                            zIndex="1"
                            bottom="0"
                            right="0"
                            mb="6vw"
                            mr="6vw"
                            t_mb="2.5vw"
                            t_mr="2.5vw"
                        >
                            <Link
                                href="/edge-machine-learning"
                                bold
                                fontColor="white"
                                size="5vw"
                                t_size="1.3vw"
                                decoration="none"
                            >
                                Learn More
                            </Link>
                            <Image src={imgArrowRight} w="4.5vw" t_w="1.5vw" pl="1vw"/>
                        </LearnMore>
                    </Content>
                </Content>
            </Content>
            <Content
                w="100vw"
                mb="1vw"
                t_mb="0.5vw"
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Content
                    bgColor="#F0F5F7"
                    w="100vw"
                    t_w="100vw"
                    display="flex"
                    flexDirection="column"
                >
                    <Content
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        my="7vw"
                        mb="5vw"
                    >
                        <Label bolder align="center" size="7vw" t_size="3vw">
                            Meet
                        </Label>
                        <Image
                            src={imgSmartVision}
                            w="60%"
                            mb="8vw"
                            t_mb="2vw"
                            loading="lazy"
                        />
                        <Content
                            w="90vw"
                            h="55vw"
                            t_h="auto"
                            t_w="43vw"
                            display="flex"
                            flexDirection="column"
                            justifyContent="space-between"
                        >
                            <Label
                                size="4.5vw"
                                t_size="1.7vw"
                                // t_lineHeight="2vw"
                                mb="10vw"
                                t_mb="0vw"
                                ml="4vw"
                                t_ml="0vw"
                                bolder
                            >
                                Our proprietary DeepTech uses Computer <br />
                                Vision, Neural Networks and Deep Learning <br />
                                models with Explainable AI and Edge Machine <br />
                                Learning processes to extract clinically relevant <br />
                                rich health data from just a selfie.
                                <sup style={{color: "#667093"}}>
                                    <Label t_size="0.8vw" size="2.5vw" bolder>
                                        4
                                    </Label>
                                </sup>
                            </Label>
                            <Content
                                w="100%"
                                display="flex"
                                justifyContent="flex-end"
                                mt="2vw"
                                t_mt="5vw"
                                mr="6vw"
                                t_mr="2.5vw"
                            >
                                <Link
                                    href="/our-technology"
                                    bold
                                    fontColor="#001242"
                                    size="4.5vw"
                                    t_size="1.4vw"
                                    display="flex"
                                    alignItems="center"
                                >
                                    <Label>Read More</Label>
                                    <Image
                                        src={imgArrowRightBlack}
                                        w="4.5vw"
                                        t_w="1.5vw"
                                        pl="1vw"
                                    />
                                </Link>
                            </Content>
                        </Content>
                    </Content>
                </Content>
            </Content>


            {/* <Content
                w="100vw"
                mb="4vw"
                t_mb="4vw"
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Label
                    bold
                    align="center"
                    w="90vw"
                    size="9vw"
                    py="10vh"
                    t_w="90vw"
                    t_size="2.8vw"
                    t_py="4vw"
                >
                    Our promise to you
                </Label>
                <Content
                    w="100vw"
                    display="flex"
                    flexDirection="column"
                    t_flexDirection="row"
                    t_justifyContent="space-between"
                >
                    <Content
                        position="relative"
                        bgColor="#D8E8FF"
                        w="100vw"
                        t_w="40vw"
                        h="80vw"
                        t_h="31vw"
                        t_px="4vw"
                        display="flex"
                        alignItems="center"
                    >
                        <Label
                            bold
                            fontColor="black"
                            w="90vw"
                            t_w="90vw"
                            size="8vw"
                            t_size="2.8vw"
                            px="6vw"
                            t_px="0"
                            py="6vw"
                            t_py="4vw"
                        >
                            Digital Inclusion & Diversity
                        </Label>
                        <LearnMore
                            display="flex"
                            alignItems="center"
                            position="absolute"
                            zIndex="1"
                            bottom="0"
                            right="0"
                            mb="6vw"
                            mr="6vw"
                            t_mb="2.5vw"
                            t_mr="2.5vw"
                        >
                            <Link
                                href="/inclusion-and-diversity"
                                bold
                                fontColor="black"
                                size="5vw"
                                t_size="1.3vw"
                                decoration="none"
                            >
                                Learn More
                            </Link>
                            <Image src={imgArrowRightBlack} w="4.5vw" t_w="1.5vw" pl="1vw" />
                        </LearnMore>
                    </Content>
                    <Content
                        position="relative"
                        bgColor="black"
                        w="100vw"
                        t_w="40vw"
                        h="80vw"
                        t_h="31vw"
                        t_px="4vw"
                        display="flex"
                        alignItems="center"
                    >
                        <Label
                            bold
                            fontColor="white"
                            w="90vw"
                            t_w="90vw"
                            size="8vw"
                            t_size="2.8vw"
                            px="6vw"
                            t_px="0"
                            py="6vw"
                            t_py="4vw"
                        >
                            Strong Privacy
                        </Label>
                        <LearnMore
                            display="flex"
                            alignItems="center"
                            position="absolute"
                            zIndex="1"
                            bottom="0"
                            right="0"
                            mb="6vw"
                            mr="6vw"
                            t_mb="2.5vw"
                            t_mr="2.5vw"
                        >
                            <Link
                                href="/strong-privacy"
                                bold
                                fontColor="white"
                                size="5vw"
                                t_size="1.3vw"
                                decoration="none"
                            >
                                Learn More
                            </Link>
                            <Image src={imgArrowRight} w="4.5vw" t_w="1.5vw" pl="1vw" />
                        </LearnMore>
                    </Content>
                </Content>
            </Content> */}

            <Content
                w="100vw"
                mb="4vw"
                t_mb="4vw"
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Content
                    w="100vw"
                    display="flex"
                    flexDirection="column"
                    t_flexDirection="row"
                    t_justifyContent="space-between"
                >
                    <Content
                        position="relative"
                        bgImage={`url(${imgBgDocForMe5})`}
                        w="100vw"
                        t_w="40vw"
                        h="80vw"
                        t_h="31vw"
                        t_px="4vw"
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        pl="5vw"
                    >
                        <Label
                            bold
                            fontColor="white"
                            w="90vw"
                            t_w="25vw"
                            size="5vw"
                            t_size="2.8vw"
                            t_py="1vw"
                            ml="10%"
                        >
                            Download DocMe today
                        </Label>
                        <Label
                            fontColor="white"
                            w="80vw"
                            t_w="22vw"
                            size="5.5vw"
                            t_size="1.7vw"
                            ml="10%"
                        >
                            Our baseline features are free and will always remain free.
                        </Label>
                    </Content>
                    <Content
                        position="relative"
                        w="100vw"
                        t_w="40vw"
                        t_p="4vw"
                        display="flex"
                        flexDirection="column"
                    >
                        <Content
                            display="flex"
                            justifyContent="flex-start"
                            mb="6vw"
                            t_mb="7vw"
                            // t_px="4vw"
                            pt="8vh"
                            pb="2vh"
                            t_py="0vh"

                        >
                            <Content
                                display="flex"
                                flexDirection="column"
                            >
                                <Label
                                    medium
                                    w="50vw"
                                    t_w="12vw"
                                    t_h="6vw"
                                    size="5.5vw"
                                    t_size="1.7vw"
                                >
                                    Download it directly
                                </Label>
                                <Image
                                    src={imgQRlinkToDocme}
                                    w="40vw"
                                    h="40vw"
                                    t_w="7vw"
                                    t_h="7vw"
                                    mt="3vh"
                                    t_mt="0vh"
                                />
                            </Content>
                            <Content
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                ml="10%"
                            >
                                <Label
                                    align="center"
                                    w="50vw"
                                    mb="7vh"
                                    t_mb="0vw"
                                    t_w="12vw"
                                    t_h="6vw"
                                    size="5.5vw"
                                    t_size="1.7vw"
                                    medium
                                >
                                    Or find it on
                                </Label>
                                <Image
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.location.href =
                                            "https://apps.apple.com/gb/app/docme/id1516996141";
                                    }}
                                    src={imgAppStore}
                                    w="40vw"
                                    t_w="10vw"
                                    style={{cursor: "pointer"}}
                                />
                                {/*<Image*/}
                                {/*    src={imgGooglePlay}*/}
                                {/*    w="40vw"*/}
                                {/*    t_mt="0.8vw"*/}
                                {/*    mt="2vw"*/}
                                {/*    t_w="10vw"*/}
                                {/*/>*/}
                                <Label t_size="0.8vw" size="2vw" medium mt="0.5vw"
                                    style={{
                                        color: global.mobileView
                                            ? "white"
                                            : "#0038FF",
                                    }}>
                                    COMING SOON
                                </Label>
                            </Content>
                        </Content>
                        <Label
                            medium
                            size="4.5vw"
                            t_size="1vw"
                            t_px="4vw"
                            px="6vw"
                            t_px="0vw"
                            mb="4vh"
                            t_mb="0vh"
                            fontColor="#000000B1"
                            w="81%"
                        >
                            To download the app, open your camera and point it towards the QR code for 2 seconds.
                            Simple. Or tap the buttons below if you're on a mobile" also, add App store buttons next to
                            the QR code
                        </Label>
                    </Content>
                </Content>
            </Content>

            <Content
                w="100vw"
                mb="4vw"
                t_mb="4vw"
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Content
                    w="100vw"
                    display="flex"
                    flexDirection="column"
                    t_flexDirection="row"
                >
                    <Content
                        bgColor="#F0F5F7"
                        w="100vw"
                        t_w="40vw"
                        h="80vw"
                        t_h="31vw"
                        t_px="8vw"
                        px="16vw"
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                    >
                        <Label
                            bold
                            fontColor="black"
                            w="90vw"
                            t_w="28vw"
                            size="6.5vw"
                            t_size="2.8vw"
                            t_mb="3vw"
                            mb="6vw"
                        >
                            Do you enjoy using DocMe?
                        </Label>
                        <Link
                            href="https://apps.apple.com/gb/app/docme/id1516996141"
                            decoration="none"
                        >
                            <Button
                                bgColor="#0038FF"
                                w="50vw"
                                h="10vw"
                                t_w="18.75vw"
                                t_h="2.8vw"
                            >
                                <Label
                                    bold
                                    fontColor="white"
                                    size="4.5vw"
                                    t_size="0.8vw"
                                    cursor="pointer"
                                >
                                    Let us know
                                </Label>
                            </Button>
                        </Link>
                    </Content>
                    <Content
                        position="relative"
                        bgColor="#D4FF8E"
                        w="100vw"
                        t_w="40vw"
                        h="80vw"
                        t_h="31vw"
                        t_px="4vw"
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                    >
                        <Label
                            bold
                            fontColor="#001242"
                            w="69vw"
                            t_w="35vw"
                            size="6.5vw"
                            t_size="2.7vw"
                            mb="6vw"
                            t_mb="1vw"
                            mx="auto"
                        >
                            We are always happy to receive your feedback.
                        </Label>
                        <Label
                            medium
                            fontColor="#001242"
                            w="56vw"
                            t_w="35vw"
                            size="4.5vw"
                            t_size="1.8vw"
                            ml="15vw"
                            t_mx="auto"
                        >
                            Check out what we are working on.
                        </Label>
                        <LearnMore
                            display="flex"
                            flexDirection="column"
                            alignItems="left"
                            position="absolute"
                            zIndex="1"
                            bottom="0"
                            right="0"
                            mb="6vw"
                            mr="6vw"
                            t_mb="4.5vw"
                            t_mr="15.5vw"
                        >
                            <Label
                                cursor="pointer"
                                bolder
                                fontColor="#667093"
                                t_size="1vw"
                                size="3.5vw"
                                t_mb="0.8vw"
                                mb="2vw"
                            >
                                COMING SOON
                            </Label>
                            <Content display="flex" flexDirection="row">
                                <Link
                                    href=""
                                    bolder
                                    fontColor="#001242"
                                    size="3vw"
                                    t_size="0.89vw"
                                    decoration="none"
                                >
                                    Community
                                </Link>
                                <Image
                                    src={imgArrowRightBlack}
                                    w="3.5vw"
                                    t_w="1.1vw"
                                    pl="1vw"
                                />
                            </Content>
                        </LearnMore>
                    </Content>
                </Content>
            </Content>

            <Content
                bgColor="#f0f5f7"
                display="flex"
                flexDirection="column"
                px="10%"
                t_pl="9%"
                py="10vw"
                t_py="4vw"
            >
                <Content
                    w="100%"
                    t_w="100%"
                    // mb="10vw"
                    // t_mb="4vw"
                    display="flex"
                    flexDirection="column"
                >
                    <Label
                        medium
                        t_mb="0vw"
                        mb="5vw"
                        size="2vw"
                        t_size="11px"
                        fontColor="#667093"
                    >
                        1. DOCME TECHNOLOGIES LTD is registered with the MHRA (registration number 10941) and licensed
                        to sell medical devices in the United Kingdom in accordance with European Directive 93/42/EEC on
                        medical devices (EU MDD) as modified by Part II of Schedule 2A of the UK Medical Device
                        Regulations 2002 as they apply in Great Britain.
                    </Label>
                    <Label
                        medium
                        t_mb="0vw"
                        mb="5vw"
                        size="2vw"
                        t_size="11px"
                        fontColor="#667093"
                    >
                        2. The DocMe App can measure heart rate (HR), respiratory rate (RR), heart rate variability
                        (HRV), and blood pressure (BP). Error margins for DocMe vital signs are currently ±8 beats/min
                        for HR and ±1.5 breaths/min for RR in 18-30 years old healthy subjects compared with previously
                        validated medical-grade devices.
                    </Label>
                    <Label
                        medium
                        t_mb="0vw"
                        mb="5vw"
                        size="2vw"
                        t_size="11px"
                        fontColor="#667093"
                    >
                        3. The technology is available only on iOS 14 and above and intended to be used on iPhone 12Pro and
                        above devices only. Features and accuracy may vary with devices. For details, check full
                        technical documentation here.
                    </Label>
                    <Label
                        medium
                        t_mb="0vw"
                        mb="5vw"
                        size="2vw"
                        t_size="11px"
                        fontColor="#667093"
                    >
                        4. Vital signs are useful in detecting or monitoring medical problems. Vital signs can be
                        measured in a medical setting, at home, at the site of a medical emergency, or elsewhere. Vitals
                        signs in the description include a)heart rate (the wave of blood in the artery created by
                        contraction of the left ventricle during a cardiac cycle) as beats/min, b) respiratory rate (the
                        number of breaths a person takes per minute) as breaths/min, c) heart rate variability (the
                        change in the time intervals between adjacent heart beats) in ms, d) blood pressure (the force
                        of the blood pushing against the artery walls during contraction and relaxation of the heart) as
                        systolic/diastolic mmHg.
                    </Label>
                    <Label
                        medium
                        t_mb="0vw"
                        mb="5vw"
                        size="2vw"
                        t_size="11px"
                        fontColor="#667093"
                    >
                        5. DocMe is designed to protect privacy and give you control over your health data. Your health
                        data
                        is hidden from us, and we cannot see your full facial video. We maximise data security through
                        data
                        minimisation, on-device intelligence, security protections, and by prioritising transparency and
                        control. Finally, DocMe is designed with digital inclusion and diversity in mind so that our
                        technology works on all skin tones and is blind to ethnicity.
                    </Label>
                </Content>
            </Content>
        </>
    );
};

const ContentHeader = styled(Content)`
  overflow-x: none;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContentImgBg = styled(Content)`
  background-image: url(${imgHowDoes1});
  background-size: cover;
  background-position: center;
`;

const LearnMore = styled(Content)`
  cursor: pointer;
`;

const FeedbackBox = styled(Content)`
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;
  -ms-overflow-style: none;
`;

export default ComputerVision;
