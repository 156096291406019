export const screens = [
    {
        id: "",
        min: `0px`,
    },
    {
        id: "xs_",
        min: `237px`,
    },
    {
        id: "t_",
        min: `426px`,
    },
    {
        id: "sl_",
        min: `769px`,
    },
    {
        id: "l_",
        min: `1025px`,
    },
    {
        id: "xl_",
        min: `1367px`,
    },
];
