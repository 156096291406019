import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"

import Content from "../atoms/Content"


const Loading = () => (
   <Content w="100%" position="relative" left="0" mt="20" px="4">
      <Content display="flex" justifyContent="center" alignItems="center">
         <FontAwesomeIcon icon={faSpinner} /> Loading...
      </Content>
   </Content>
)

export default Loading