import React, { useState } from "react";

import Content from "../atoms/Content";
import Label from "../atoms/Label";
import Link from "../atoms/Link";

const CookieBannerDocme = () => {
    const [openBanner, setOpenBanner] = useState(true);

    const closeBanner = () => {
        setOpenBanner(false);
    };

    return (
        <>
            {openBanner && (
                <Content
                    display="flex"
                    flexDirection="column"
                    t_flexDirection="row"
                    justifyContent="center"
                    t_alignItems="center"
                    position="fixed"
                    borderRadius="10px"
                    bottom="2vw"
                    t_bottom="1vw"
                    h="34vw"
                    t_h="5vw"
                    w="80vw"
                    t_w="60vw"
                    mx="5vw"
                    t_mx="19vw"
                    p="3.5vw 5vw"
                    t_p="0.6vw 0.8vw"
                    zIndex="999"
                    bgColor="white"
                    boxShadow="1px 1px 6px #0000001F"
                >
                    <Content display="flex" flexDirection="column" w="100%" t_w="60%" t_mt="0.2vw">
                        <Label
                            size="3vw"
                            t_size="0.7vw"
                            fontColor="#667093"
                            medium
                            mb="1vw"
                            t_mb="0.2vw "
                        >
                            {" "}
                            We use  🍪 to personalise your DocMe experience.
                        </Label>
                        <Label size="3vw" t_size="0.7vw" fontColor="#667093" medium>
                            By clicking "Accept", you agree to the storing of cookies on your device
                            to enhance site navigation, analyse site usage, and assist in our
                            marketing efforts. Read our{" "}
                            <Link href="/docme/cookie-policy" fontColor="#053FFF">Cookie Policy</Link>.
                        </Label>
                    </Content>
                    <Content
                        display="flex"
                        flexDirection="row-reverse"
                        t_flexDirection="row"
                        w="50%"
                        t_w="35%"
                        justifyContent="space-evenly"
                        alignItems="center"
                    >
                        <Label
                            bolder
                            fontColor="#4F6E8C"
                            w="10.5vw"
                            t_w="3.5vw"
                            mt="3vw"
                            t_my="0vw"
                            ml="4vw"
                            t_ml="0vw"
                            size="3vw"
                            t_size="0.8vw"
                            t_p="1vw 3vw"
                            p="2.5vw 8vw"
                            borderRadius="15px"
                            cursor="pointer"
                            onClick={() => closeBanner()}
                        >
                            Decline
                        </Label>
                        <Label
                            bold
                            fontColor="#053FFF"
                            w="10.5vw"
                            t_w="3.5vw"
                            mt="3vw"
                            t_my="0vw"
                            size="3vw"
                            t_size="0.8vw"
                            t_p="1vw 3vw"
                            p="2.5vw 8vw"
                            borderRadius="15px"
                            border="1px solid #053FFF"
                            t_ml="2vw"
                            ml="20vw"
                            cursor="pointer"
                            onClick={() => closeBanner()}
                        >
                            Accept
                        </Label>
                    </Content>
                </Content>
            )}
        </>
    );
};

export default CookieBannerDocme;
