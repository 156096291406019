import React, { useContext, useRef } from "react";
import styled from "styled-components";

import { GlobalContext } from "../../App";

import imgBg from "../../assets/images/people/bg.jpg";

import imgPerfexiaHeader from "../../assets/images/perfexia_redesigned/Perfexia_header_image.jpg";
import imgTechnology from "../../assets/images/perfexia_redesigned/section_technology.jpg";
import imgProduct from "../../assets/images/perfexia_redesigned/Perfexia_Product.jpg";

import imgDocmeForMe from "../../assets/images/perfexia_docme_for_me.jpg";
import imgDocmeForDoctors from "../../assets/images/perfexia_docme_for_doctors.jpg";

import imgArrowRightBlack from "../../assets/images/docme_redesigned/arrow_right_black.svg";
import imgArrowRight from "../../assets/images/icons/icon_24px_right_arrow.svg";

import imgP_NHS from "../../assets/images/docme/Partner_1_NHS_OUH.png";
import imgP_Cam from "../../assets/images/docme/Partner_2_Cambridge.png";
import imgP_Dell from "../../assets/images/docme/Partner_4_Dell_Medical_School.png";
import imgP_Kings from "../../assets/images/docme/Partner_Kings_College.png";
import imgP_Pan from "../../assets/images/docme/Partner_5_Panacea_Stars.png";
import imgP_newchip from "../../assets/images/docme/Partner_6_newchip – 1.svg";
import imgP_NHSMAA from "../../assets/images/docme/Partner_7_NHS_Market_Access_Accelerator.png";
import imgP_NIHR from "../../assets/images/docme/Partner_8_National_Institute_for_Health_Research_NIHR.png";

import Content from "../atoms/Content";
import Image from "../atoms/Image";
import Label from "../atoms/Label";
import Button from "../atoms/Button";
import Link from "../atoms/Link";
import NavbarDocMe from "../organisms/NavbarDocMe";

import MetaTags from "react-meta-tags";
import SliderSlick from "react-slick";

const Landing = () => {
  const global = useContext(GlobalContext);
  const forMe = useRef();
  const forDoctors = useRef();
  const broadeningAccess = useRef();

  const mobileScreen = window.innerWidth < 475 ? true : false;

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: mobileScreen ? 2 : 3,
    slidesToScroll: mobileScreen ? 2 : 3,
    autoplay: true,
    autoplaySpeed: 4000,
  };

  return (
    <>
      <MetaTags>
        <meta
          name="description"
          content="We develop Software as a Medical Device (SaMD) that transforms health apps into a medical-grade tool to measure and monitor important vital signs from a video selfie, anytime and anywhere without the need for any additional devices. With 100% deep learning, we’re developing a simple and attractive solution to quickly view health trends over time by combining vital signs data with scientifically validated algorithms to give people a better overall view of their health."
        />
      </MetaTags>
      <NavbarDocMe />

      <ContentHeader pb="8vw">
        <Content
          w="100vw"
          pr="0"
          t_pr="0%"
          mt="25%"
          t_mt="14%"
          sl_mt="11%"
          l_mt="5%"
          display="flex"
          justifyContent="center"
        >
          <Content
            w="100%"
            position="relative"
            display="flex"
            flexDirection="column"
            t_flexDirection="row-reverse"
            justifyContent="space-around"
          >
            <Content
              display="block"
              t_display="none"
              ml="8vw"
              t_ml="0vw"
              pt="6vw"
              t_pt="0vw"
            >
              <Label xBold fontColor="#001242" w="86vw" mt="6vw" size="10vw">
                Welcome to the <br /> healthcare of tomorrow
              </Label>
            </Content>

            <Content
              zIndex="0"
              position="relative"
              right="0"
              t_bottom="-44vw"
              // t_right="20"
              t_bottom="0"
              display="flex"
              flexDirection="column"
              mb="6vw"
              t_mb="0vw"
              ml="auto"
              t_ml="0vw"
            >
              <Image
                src={imgPerfexiaHeader}
                w="96vw"
                opacity="1"
                t_w="41vw"
                t_opacity="1"
              />
              <Label
                display="none"
                t_display="block"
                fontColor="#667093"
                align="left"
                t_size="0.8vw"
                t_w="43vw"
                // t_mx="auto"
                t_lineHeight="2vw"
                medium
                breakSpaces
              >
                Our mission is to make the world <Span>a healthier place</Span>{" "}
                by encouraging people <Span>to take selfies.</Span>
              </Label>
              <Label
                display="block"
                t_display="none"
                fontColor="#667093"
                align="left"
                size="3.5vw"
                w="62vw"
                ml="5vw"
                lineHeight="8vw"
                medium
                breakSpaces
              >
                Our mission is to make the world <Span>a healthier place</Span>{" "}
                by
              </Label>
              <Label
                display="block"
                t_display="none"
                fontColor="#667093"
                align="left"
                size="3.5vw"
                t_w="32vw"
                w="74vw"
                ml="5vw"
                lineHeight="8vw"
                medium
                breakSpaces
              >
                encouraging people <Span>to take selfies.</Span>
              </Label>
            </Content>
            <Content
              ml="9vw"
              zIndex="1"
              display="flex"
              flexDirection="column"
              t_mt="3vw"
              sl_mt="3vw"
            >
              <Label
                display="none"
                t_display="block"
                xBold
                fontColor="#001242"
                w="72vw"
                t_w="45vw"
                my="6vw"
                t_my="3vw"
                size="8vw"
                t_size="3.13vw"
              >
                Welcome to the <br /> healthcare of tomorrow
              </Label>
              <Label
                w="80vw"
                t_w="38vw"
                sl_w="48vw"
                mb="6vw"
                t_mb="3vw"
                fontColor="#4F6E8C"
                size="4.75vw"
                t_size="1.4vw"
              >
                We develop Software as a Medical Device (SaMD) that transforms
                health apps into a medical-grade tool to measure and monitor
                important vital signs from a video selfie, anytime and anywhere
                without the need for any additional devices. <br />
                With 100% deep learning, we’re developing a simple and
                attractive solution to quickly view health trends over time by
                combining vital signs data with scientifically validated
                algorithms to give people a better overall view of their health.
              </Label>

              <Link href="/our-technology" decoration="none">
                <Button
                  bgColor="#0577ff"
                  w="80vw"
                  h="13vw"
                  t_w="17vw"
                  t_h="2.8vw"
                >
                  <Label
                    bold
                    fontColor="white"
                    size="4.5vw"
                    t_size="1vw"
                    cursor="pointer"
                  >
                    Learn More
                  </Label>
                </Button>
              </Link>
            </Content>
          </Content>
        </Content>
      </ContentHeader>

      <Content
        className="docmePartnerSlider"
        w="100%"
        t_mb="6vw"
        mb="22vw"
        pt="8vw"
        t_pt="0"
        display="flex"
        flexDirection="column"
      >
        <Label
          t_mb="2vw"
          bold
          size="6vw"
          t_size="2.5vw"
          t_pt="8vw"
          pt="8vw"
          pb="8vw"
          t_py="4vw"
          fontColor="#667093"
          t_px="10vw"
          px="20vw"
          pl="10vw"
        >
          We’re working with trusted organisations
          <br /> in healthcare, policy and beyond.
        </Label>
        <SliderSlick
          className="docmeSliderSlick"
          style={{ width: "85vw", margin: "0 auto" }}
          {...settings}
        >
          <div>
            <Image src={imgP_NHS} t_w="80%" />
          </div>
          <div>
            <Image src={imgP_Cam} t_w="80%" />
          </div>
          <div>
            <Image src={imgP_Kings} t_w="80%" />
          </div>
          <div>
            <Image src={imgP_Dell} t_w="80%" />
          </div>
          <div>
            <Image src={imgP_Pan} t_w="80%" />
          </div>
          <div>
            <Image src={imgP_newchip} t_w="80%" />
          </div>
          <div>
            <Image src={imgP_NHSMAA} t_w="80%" />
          </div>
          <div>
            <Image src={imgP_NIHR} t_w="80%" />
          </div>
        </SliderSlick>
      </Content>
      <Content
        w="100vw"
        my="8vw"
        t_my="10vw"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Content
          w="82vw"
          t_w="66vw"
          t_px="12.5vw"
          display="flex"
          flexDirection="column"
        >
          <Label
            size="4.5vw"
            t_size="1.8vw"
            t_lineHeight="2vw"
            fontColor="#4F6E8C"
            normal
          >
            We ignite opportunity by encouraging people to take selfies. What
            starts as the tap of a button on your smartphone opens the doorway
            to accessing improved care & health outcomes with the help of our
            proprietary technology that can spot all kinds of patterns in
            non-obvious ways.
          </Label>
        </Content>
      </Content>

      <Content
        w="100vw"
        mb="4vw"
        t_mb="4vw"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Content
          w="100vw"
          display="flex"
          flexDirection="column"
          t_flexDirection="row"
          t_justifyContent="space-evenly"
        >
          <Content
            bgColor="#F6F6F8"
            w="100vw"
            t_w="49vw"
            display="flex"
            flexDirection="column"
          >
            <Image
              src={imgTechnology}
              t_w="49vw"
              w="100vw"
              t_h="auto"
              h="50vw"
            />
            <Label
              bold
              fontColor="#001242"
              w="90vw"
              t_w="20vw"
              size="8vw"
              t_size="2.8vw"
              p="6vw"
              t_px="0"
              t_py="4vw"
              m="10vw 0vw 20vw 4vw"
              t_m="0vw 0vw 4vw 3vw"
            >
              Technology
            </Label>
            <LearnMore
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              mb="6vw"
              mr="6vw"
              t_mb="2.5vw"
              t_mr="2.5vw"
            >
              <Link
                href="/our-technology"
                bold
                fontColor="#001242"
                size="5vw"
                t_size="1.3vw"
                decoration="none"
              >
                Learn More
              </Link>
              <Image src={imgArrowRightBlack} w="4.5vw" t_w="1.5vw" pl="1vw" />
            </LearnMore>
          </Content>{" "}
          <Content
            bgColor="#F6F6F8"
            w="100vw"
            t_w="49vw"
            display="flex"
            flexDirection="column"
          >
            <Image src={imgProduct} t_w="49vw" w="100vw" t_h="auto" h="50vw" />
            <Label
              bold
              fontColor="#001242"
              w="90vw"
              t_w="20vw"
              size="8vw"
              t_size="2.8vw"
              p="6vw"
              t_px="0"
              t_py="4vw"
              m="10vw 0vw 20vw 4vw"
              t_m="0vw 0vw 4vw 3vw"
            >
              Product
            </Label>
            <LearnMore
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              mb="6vw"
              mr="6vw"
              t_mb="2.5vw"
              t_mr="2.5vw"
            >
              <Link
                href="/docme"
                bold
                fontColor="#001242"
                size="5vw"
                t_size="1.3vw"
                decoration="none"
              >
                Learn More
              </Link>
              <Image src={imgArrowRightBlack} w="4.5vw" t_w="1.5vw" pl="1vw" />
            </LearnMore>
          </Content>
        </Content>
      </Content>

      <Content
        ref={broadeningAccess}
        w="100vw"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Label
          w="85vw"
          xBold
          fontColor="#001242"
          align="left"
          t_align="center"
          py="8vw"
          size="7.5vw"
          t_size="3vw"
          t_py="4vw"
          ml="3vw"
          t_ml="0vw"
        >
          We are broadening access to healthcare.{" "}
        </Label>
      </Content>

      <Content
        w="100vw"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Content
          w="82vw"
          t_w="66vw"
          t_px="12.5vw"
          display="flex"
          flexDirection="column"
        >
          <Label
            size="4.5vw"
            t_size="1.8vw"
            t_lineHeight="2vw"
            fontColor="#4F6E8C"
            normal
          >
            We facilitate the active management of minor, self-limiting
            conditions as well as taking a greater role in any diagnosed medical
            conditions that involve partnership with healthcare professionals.
            Our technology helps doctors connect remotely with people and
            conduct ground-breaking medical research. The result is care that
            becomes more efficient and more personalised.
          </Label>
        </Content>
      </Content>

      <Content
        w="100vw"
        mb="4vw"
        t_mb="4vw"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Label
          bold
          align="center"
          w="90vw"
          size="9vw"
          t_w="90vw"
          t_size="2.8vw"
          py="14vw"
          t_py="4vw"
          fontColor="#001242"
        >
          Learn More
        </Label>
        <Content
          w="100vw"
          display="flex"
          flexDirection="column"
          t_flexDirection="row"
          t_justifyContent="space-between"
        >
          <Content
            position="relative"
            bgColor="#D8E8FF"
            w="100vw"
            t_w="40vw"
            h="80vw"
            t_h="31vw"
            t_px="4vw"
            display="flex"
            flexDirection="column"
          >
            <Label
              bold
              fontColor="#001242"
              w="74vw"
              t_w="90vw"
              size="8vw"
              t_size="2.8vw"
              p="20vw 0vw 23vw 10vw"
              t_p="9vw 0vw 13vw 0vw"
            >
              Company overview
            </Label>
            <LearnMore
              display="flex"
              justifyContent="flex-end"
              mb="6vw"
              mr="6vw"
              t_mb="2.5vw"
              t_mr="2.5vw"
            >
              <Link
                href="/company-overview"
                bold
                fontColor="black"
                size="5vw"
                t_size="1.3vw"
                decoration="none"
              >
                Learn More
              </Link>
              <Image src={imgArrowRightBlack} w="4.5vw" t_w="1.5vw" pl="1vw" />
            </LearnMore>
          </Content>
          <Content
            position="relative"
            bgColor="black"
            w="100vw"
            t_w="40vw"
            h="80vw"
            t_h="31vw"
            t_px="4vw"
            display="flex"
            flexDirection="column"
          >
            <Label
              bold
              fontColor="white"
              w="50vw"
              t_w="20vw"
              size="8vw"
              t_size="2.8vw"
              p="20vw 0vw 32vw 10vw"
              t_p="9vw 0vw 13vw 0vw"
            >
              Our team
            </Label>
            <LearnMore
              display="flex"
              justifyContent="flex-end"
              mb="6vw"
              mr="6vw"
              t_mb="2.5vw"
              t_mr="0.5vw"
            >
              <Link
                href="/our-team"
                bold
                fontColor="white"
                size="5vw"
                t_size="1.3vw"
                decoration="none"
              >
                Learn More
              </Link>
              <Image src={imgArrowRight} w="4.5vw" t_w="1.5vw" pl="1vw" />
            </LearnMore>
          </Content>
        </Content>
      </Content>
      {/*
            <ContentGradient
                py="20vw"
                t_py="5vw"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                t_flexDirection="row"
                t_justifyContent="space-between"
            >
                <Content
                    w="300px"
                    ml="5.6vw"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    t_alignItems="flex-start"
                >
                    <Label w="84vw" t_w="auto" bold size="7vw" t_size="2.65vw">
                        Awards & achievements
                    </Label>
                    <Link href="/newsroom" mt="10vw" mb="8vw" t_mt="3vw" t_mb="0" decoration="none">
                        <Button
                            fullWitdh
                            bgColor="#0577ff"
                            w="50vw"
                            h="10vw"
                            t_w="17vw"
                            t_h="2.8vw"
                        >
                            <Label
                                bold
                                fontColor="white"
                                size="4.5vw"
                                t_size="1.2vw"
                                cursor="pointer"
                            >
                                Learn More
                            </Label>
                        </Button>
                    </Link>
                </Content>
                <AwardsBox display="flex" w="100vw" mr="10vw" t_ml="10vw" mt="15vw" t_mt="0vw">
                    <Image
                        src={imgPressRelease1}
                        w="75vw"
                        h="50vw"
                        t_w="30vw"
                        t_h="20vw"
                        m="auto 10vw"
                    />
                    <Image
                        src={imgPressRelease2}
                        w="75vw"
                        h="50vw"
                        t_w="30vw"
                        t_h="20vw"
                        mx="0"
                        t_mx="1vw"
                        t_mt="0"
                        mr="13vw"
                    />
                </AwardsBox>
            </ContentGradient> */}

      {/* <Content bgColor="#f0f5f7" display="flex" flexDirection="column" px="10%" py="10">
                <Content display="flex" flexDirection="column" t_flexDirection="row">
                    <Content display="flex" flexDirection="column" mb="5vw" t_mb="0">
                        <Label bold size="7vw" t_size="4.2vw" mb="3vw" t_mb="1vw">
                            Anytime, Anywhere.
                            <sup style={{ color: "#667093" }}>
                                <Label bolder t_size="1.5vw" size="4vw">
                                    *
                                </Label>
                            </sup>{" "}
                        </Label>
                    </Content>
                    <Image src={imgWorldWide} w="80vw" t_w="47vw" />
                </Content>
                <Content w="80vw" t_w="62vw" mb="10">
                    <Label fontColor="#667093" size="2.5vw" t_size="0.8vw">
                        * Our baseline features are free and will always remain free. The
                        information provided on this web page is intended for informational purposes
                        only and some features may not be applicable in your country, region, or
                        city. It is subject to change and may be updated without notice.
                    </Label>
                </Content>
            </Content> */}
    </>
  );
};

const ContentHeader = styled(Content)`
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: #ebf0f3;
  /* background-image: url(${imgBg});
    background-size: cover; */
  width: 100vw;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const DocMeImageBox = styled(Content)`
  background-image: url(${imgDocmeForMe});
  background-size: cover;
  background-position: center;
`;

const DocDoctorsImageBox = styled(Content)`
  background-image: url(${imgDocmeForDoctors});
  background-size: cover;
  background-position: center;
`;

const ContentGradient = styled(Content)`
  background-image: url(${imgBg});
  background-size: cover;
  background-position-y: top;
  width: 100vw;
  display: flex;
  align-items: center;
`;

const AwardsBox = styled(Content)`
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
`;

const Span = styled.span`
  background: white;
  border-radius: 15px;
  padding: 0.2vw 0.6vw;
  @media only screen and (max-width: 426px) {
    padding: 1vw 2vw;
  }
`;

const LearnMore = styled(Content)`
  cursor: pointer;
`;

export default Landing;
