import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTwitter, faLinkedin, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"

import Content from "../atoms/Content"
import Link from "../atoms/Link"
import Label from "../atoms/Label"


const Social = ({ fontColor="black", ...props }) => (
   <Content {...props} display="flex" justifyContent="space-around" alignItems="center">
      <Label medium size="16px" fontColor={fontColor}>Follow Us</Label>
      <Link size="1rem" fontColor={fontColor} href="https://twitter.com/perfexiahealth?lang=en">
         <FontAwesomeIcon icon={faTwitter} />
      </Link>
      <Link size="1rem" fontColor={fontColor} href="https://www.linkedin.com/company/perfexia-healthtech">
         <FontAwesomeIcon icon={faLinkedin} />
      </Link>
      <Link size="1rem" fontColor={fontColor} href="https://www.instagram.com/docme/">
         <FontAwesomeIcon icon={faInstagram} />
      </Link>
      <Link size="1rem" fontColor={fontColor} href="https://www.youtube.com/channel/UC2ltApAUkHv33HfR_aw5rNQ">
         <FontAwesomeIcon icon={faYoutube} />
      </Link>
      <Link size="1rem" fontColor={fontColor} href="mailto:hello@docme.ai">
         <FontAwesomeIcon icon={faEnvelope} />
      </Link>
   </Content>
)

export default Social
