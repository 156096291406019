import React, { useContext, useEffect, useState } from "react";

import styled from "styled-components";
import { Helmet } from "react-helmet";

import { GlobalContext } from "../../App";

import imgCloseMenu from "../../assets/images/docme_redesigned/close_icon_blue.png"
import imgBurgerMenu from "../../assets/images/docme_redesigned/menu.svg";

import imgPlus from "../../assets/images/docme_redesigned/plus.svg";
import imgMinus from "../../assets/images/docme_redesigned/minus.svg";

import favIconLogo from "../../assets/images/Favicon_DocMe_32_32pt.png";

import logo from "../../assets/images/docme_redesigned/DocMe_logo_mobile.svg";
import Content from "../atoms/Content";
import Image from "../atoms/Image";
import Link from "../atoms/Link";

import Button from "../atoms/Button";
import Label from "../atoms/Label";
import Dropdown from "../molecules/DropdownDocme";
import CookieBannerDocme from "../molecules/CookieBannerDocme";
import Docme from "../templates/Docme";

const NavbarDocMe = ({ prop }) => {
    const global = useContext(GlobalContext);
    const [dropdown, setDropdown] = useState({
        showMenu: false,
        one: false,
        two: false,
        three: false,
    });

    const handleToggle = () => setDropdown({ ...dropdown, showMenu: !dropdown.showMenu });

    useEffect(() => {
        document.title = "DocMe";
    }, []);

    return (
        <>
            <>
                <Helmet>
                    <link rel="icon" type="image/png" sizes="32x32" href={favIconLogo} />
                </Helmet>

                <Content
                    zIndex="999"
                    position="fixed"
                    mb="10vw"
                    bgColor="white"
                    top="0"
                    w="100vw"
                    py="2.8%"
                    display="flex"
                    justifyContent="center"
                    t_py="0"
                    boxShadow="0 0.0625rem 0.375rem 0 rgba(0, 0, 0, 0.1)"
                >
                    <Content
                        w="96vw"
                        t_w="82vw"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Link href="/">
                            <Image
                                src={logo}
                                h="9vw"
                                t_h="5vw"
                                sl_h="2.5vw"
                                t_py="0vw"
                                t_mr="2vw"
                                sl_mr="5vw"
                            />
                        </Link>
                        {global.mobileView && (
                            <Content display="flex" flexDirection="row" alignItems="center">
                                <Link
                                    href="https://apps.apple.com/gb/app/docme/id1516996141"
                                    t_pl="0"
                                    t_py="0.8vw"
                                >
                                    <Button bgColor="#0038FF" borderRadius="500px" w="46vw">
                                        <Label
                                            bold
                                            fontColor="#fff"
                                            t_py="0.6vw"
                                            py="1.6vw"
                                            t_px="0.8vw"
                                            px="2vw"
                                            size="4vw"
                                            t_size="0.9vw"
                                        >
                                            Signup today
                                        </Label>
                                    </Button>
                                </Link>
                                <Button
                                    onClick={handleToggle}
                                    bgColor="transparent"
                                    fontColor="#0577ff"
                                    h="15vw"
                                >
                                    {!dropdown.showMenu ? (
                                        <Image src={imgBurgerMenu} />
                                    ) : (
                                        <Image w="7.8vw" h="7.5vw" src={imgCloseMenu} />
                                    )}
                                </Button>
                            </Content>
                        )}
                        <Content
                            position="absolute"
                            t_position="relative"
                            left="0"
                            top="20vw"
                            t_top="0"
                            bgColor="white"
                        >
                            {(dropdown.showMenu || !global.mobileView) && (
                                <Content
                                    w="100vw"
                                    t_w="69vw"
                                    display="flex"
                                    flexDirection="column"
                                    t_flexDirection="row"
                                    t_justifyContent="space-between"
                                    t_display="flex"
                                >
                                    <Content
                                        display="flex"
                                        flexDirection="column"
                                        t_flexDirection="row"
                                        justifyContent="space-around"
                                        alignItems="flex-start"
                                        t_alignItems="center"
                                        bgColor={global.mobileView && "#0038FF"}
                                        pt="20vw"
                                        pb="95vw"
                                        t_py="0vw"
                                    >
                                        <Link
                                            href="/"
                                            size="7vw"
                                            t_size="2vw"
                                            sl_size="16px"
                                            decoration="none"
                                            ml="9vw"
                                            mr="0"
                                            my="4vw"
                                            t_ml="0.5vw"
                                            t_mr="1.5vw"
                                            t_my="0"
                                            t_pt="1.5vw"
                                            t_pb="1.4vw"
                                            style={{
                                                color: global.mobileView ? "white" : "#001242",
                                                fontWeight: global.mobileView
                                                    ? "300"
                                                    : "600",
                                            }}
                                        >
                                            Personal
                                        </Link>
                                        <Link
                                            href="/doctors"
                                            target="_blank"
                                            size="7vw"
                                            t_size="2vw"
                                            sl_size="16px"
                                            decoration="none"
                                            ml="9vw"
                                            mr="0"
                                            my="4vw"
                                            t_ml="0.5vw"
                                            t_mr="1.5vw"
                                            t_my="0"
                                            t_pt="1.5vw"
                                            t_pb="1.4vw"
                                            style={{
                                                color: global.mobileView ? "white" : "#4F6E8C",
                                                fontWeight: global.mobileView ? "300" : "400",
                                                display: global.mobileView ? "none" : "block"
                                            }}
                                        >
                                            Corporate
                                        </Link>

                                        {(dropdown.showMenu || !global.mobileView) && (
                                            <Content
                                                display="flex"
                                                flexDirection="column"
                                                t_flexDirection="row"
                                                justifyContent="space-between"
                                                t_ml="3vw"
                                                sl_ml="12vw"
                                                l_ml="21vw"
                                                t_mr="5vw"
                                            >
                                                <Dropdown
                                                    show={dropdown.one}
                                                    onClick={() =>
                                                        setDropdown({
                                                            ...dropdown,
                                                            one: !dropdown.one,
                                                            two: false,
                                                            three: false,
                                                        })
                                                    }
                                                    label={
                                                        <Content
                                                            display="flex"
                                                            alignItems="center"
                                                            w="82vw"
                                                            t_w="13vw"
                                                            sl_w="14vw"
                                                            l_w="10vw"
                                                            xl_w="6vw"
                                                            justifyContent="space-between"
                                                        >
                                                            <Label
                                                                noSelect
                                                                size="7vw"
                                                                t_size="2vw"
                                                                sl_size="16px"
                                                                cursor="pointer"
                                                                style={{
                                                                    color: global.mobileView
                                                                        ? "white"
                                                                        : "#4F6E8C",
                                                                    fontWeight: global.mobileView
                                                                        ? "500"
                                                                        : "400",
                                                                }}
                                                            >
                                                                the App
                                                            </Label>
                                                            {global.mobileView && dropdown.one ? (
                                                                <Image src={imgMinus} />
                                                            ) : global.mobileView ? (
                                                                <Image src={imgPlus} />
                                                            ) : null}
                                                        </Content>
                                                    }
                                                    ml="9vw"
                                                    t_ml="0vw"
                                                    my="4vw"
                                                    t_my="0"
                                                    mr="0"
                                                    t_mr="0.5vw"
                                                    sl_mr="0vw"
                                                    l_mr="1vw"
                                                    xl_mr="0.5vw"
                                                >{global.mobileView && <Hr style={{marginLeft: "5vw"}} />}
                                                    <Link
                                                        href="/#howDocmeWorks"
                                                        fontColor={
                                                            global.mobileView ? "white" : "#4F6E8C"
                                                        }
                                                        size="7vw"
                                                        t_size="2vw"
                                                        sl_size="0.8vw"
                                                        p="4"
                                                        hover-bgColor={
                                                            !global.mobileView && "#f1f1f1"
                                                        }
                                                        border="1px 0"
                                                        borderColor="#eaeaea"
                                                        decoration="none"
                                                        style={{
                                                            fontWeight: global.mobileView
                                                                ? "300"
                                                                : "400",
                                                            width: global.mobileView ? "100vw" : ""
                                                        }}
                                                    >
                                                        How DocMe works
                                                    </Link>
                                                    {global.mobileView ? "" : <Hr style={{width: "10vw", borderTop: "1px solid #F7F7F7",  marginBottom: "0", marginTop: "0"  }} />}
                                                    {global.mobileView && <Hr style={{marginLeft: "5vw"}} />}
                                                    <Link
                                                        href="/our-technology"
                                                        fontColor={
                                                            global.mobileView ? "white" : "#4F6E8C"
                                                        }
                                                        size="7vw"
                                                        t_size="2vw"
                                                        sl_size="0.8vw"
                                                        p="4"
                                                        hover-bgColor={
                                                            !global.mobileView && "#f1f1f1"
                                                        }
                                                        border="1px 0"
                                                        borderColor="#eaeaea"
                                                        decoration="none"
                                                        style={{
                                                            fontWeight: global.mobileView
                                                                ? "300"
                                                                : "400",
                                                            width: global.mobileView ? "100vw" : ""
                                                        }}
                                                    >
                                                        Technology
                                                    </Link>
                                                </Dropdown>
                                                {global.mobileView && <Hr style={{marginLeft: "10vw"}} />}
                                                <Dropdown
                                                    show={dropdown.two}
                                                    onClick={() =>
                                                        setDropdown({
                                                            ...dropdown,
                                                            one: false,
                                                            two: !dropdown.two,
                                                            three: false,
                                                        })
                                                    }
                                                    label={
                                                        <Content
                                                            display="flex"
                                                            alignItems="center"
                                                            w="82vw"
                                                            t_w="4vw"
                                                            justifyContent="space-between"
                                                        >
                                                            <Label
                                                                noSelect
                                                                size="7vw"
                                                                t_size="2vw"
                                                                sl_size="16px"
                                                                cursor="pointer"
                                                                style={{
                                                                    color: global.mobileView
                                                                        ? "white"
                                                                        : "#4F6E8C",
                                                                    fontWeight: global.mobileView
                                                                        ? "500"
                                                                        : "400",
                                                                }}
                                                            >
                                                                About
                                                            </Label>
                                                            {global.mobileView && dropdown.two ? (
                                                                <Image src={imgMinus} />
                                                            ) : global.mobileView ? (
                                                                <Image src={imgPlus} />
                                                            ) : null}
                                                        </Content>
                                                    }
                                                    ml="9vw"
                                                    t_ml="0vw"
                                                    my="4vw"
                                                    t_my="0"
                                                    mr="0"
                                                    t_mr="2.5vw"
                                                    l_mr="1.5vw"
                                                >{global.mobileView && <Hr style={{marginLeft: "5vw"}}/>}
                                                    <Link
                                                        href="/company-overview"
                                                        normal
                                                        fontColor={
                                                            global.mobileView ? "white" : "#4F6E8C"
                                                        }
                                                        size="7vw"
                                                        t_size="2vw"
                                                        sl_size="0.8vw"
                                                        p="4"
                                                        hover-bgColor={
                                                            !global.mobileView && "#f1f1f1"
                                                        }
                                                        border="1px 0"
                                                        borderColor="#eaeaea"
                                                        decoration="none"
                                                        display="flex"
                                                        alignItems="center"
                                                        cursor="pointer"
                                                        style={{
                                                            fontWeight: global.mobileView
                                                                ? "300"
                                                                : "400",
                                                            width: global.mobileView ? "100vw" : ""
                                                        }}
                                                    >
                                                        <Label>About us</Label>
                                                    </Link>
                                                    {global.mobileView ? "" : <Hr style={{width: "10vw", borderTop: "1px solid #F7F7F7",  marginBottom: "0", marginTop: "0"  }} />}
                                                    {global.mobileView && <Hr style={{marginLeft: "5vw"}} />}
                                                    <Link
                                                        href="/newsroom"
                                                        normal
                                                        fontColor={
                                                            global.mobileView ? "white" : "#4F6E8C"
                                                        }
                                                        size="7vw"
                                                        t_size="2vw"
                                                        sl_size="0.8vw"
                                                        p="4"
                                                        hover-bgColor={
                                                            !global.mobileView && "#f1f1f1"
                                                        }
                                                        decoration="none"
                                                        display="flex"
                                                        alignItems="center"
                                                        cursor="pointer"
                                                        style={{
                                                            fontWeight: global.mobileView
                                                                ? "300"
                                                                : "400",
                                                            width: global.mobileView ? "100vw" : ""

                                                        }}
                                                    >
                                                        <Label>Newsroom</Label>
                                                    </Link>
                                                </Dropdown>
                                                {global.mobileView && <Hr style={{marginLeft: "10vw"}} />}
                                                <Dropdown
                                                    show={dropdown.three}
                                                    onClick={() =>
                                                        setDropdown({
                                                            ...dropdown,
                                                            one: false,
                                                            two: false,
                                                            three: !dropdown.three,
                                                        })
                                                    }
                                                    label={
                                                        <Content
                                                            display="flex"
                                                            alignItems="center"
                                                            w="82vw"
                                                            t_w="4vw"
                                                            justifyContent="space-between"
                                                        >
                                                            <Label
                                                                noSelect
                                                                size="7vw"
                                                                t_size="2vw"
                                                                sl_size="16px"
                                                                cursor="pointer"
                                                                style={{
                                                                    color: global.mobileView
                                                                        ? "white"
                                                                        : "#4F6E8C",
                                                                    fontWeight: global.mobileView
                                                                        ? "500"
                                                                        : "400",
                                                                }}
                                                            >
                                                                Help
                                                            </Label>
                                                            {global.mobileView && dropdown.three ? (
                                                                <Image src={imgMinus} />
                                                            ) : global.mobileView ? (
                                                                <Image src={imgPlus} />
                                                            ) : null}
                                                        </Content>
                                                    }
                                                    ml="9vw"
                                                    t_ml="0vw"
                                                    my="4vw"
                                                    t_my="0"
                                                    mr="0"
                                                    t_mr="1.5vw"
                                                >
                                                    {global.mobileView && <Hr style={{marginLeft: "5vw"}} />}
                                                    <Link
                                                        href="/docme/help"
                                                        fontColor={
                                                            global.mobileView ? "white" : "#4F6E8C"
                                                        }
                                                        size="7vw"
                                                        t_size="2vw"
                                                        sl_size="0.8vw"
                                                        p="4"
                                                        hover-bgColor={
                                                            !global.mobileView && "#f1f1f1"
                                                        }
                                                        border="1px 0"
                                                        borderColor="#eaeaea"
                                                        decoration="none"
                                                        style={{
                                                            fontWeight: global.mobileView
                                                                ? "300"
                                                                : "400",
                                                            width: global.mobileView ? "100vw" : ""
                                                        }}
                                                    >
                                                        Help center
                                                    </Link>
                                                    {global.mobileView ? "" : <Hr style={{width: "10vw", borderTop: "1px solid #F7F7F7",  marginBottom: "0", marginTop: "0"  }} />}
                                                    {global.mobileView && <Hr style={{marginLeft: "5vw"}} />}
                                                    <Link
                                                        fontColor={
                                                            global.mobileView ? "white" : "#4F6E8C"
                                                        }
                                                        size="7vw"
                                                        t_size="2vw"
                                                        sl_size="0.8vw"
                                                        p="4"
                                                        hover-bgColor={
                                                            !global.mobileView && "#f1f1f1"
                                                        }
                                                        border="1px 0"
                                                        borderColor="#eaeaea"
                                                        decoration="none"
                                                        style={{
                                                            fontWeight: global.mobileView
                                                                ? "300"
                                                                : "400",
                                                            width: global.mobileView ? "100vw" : ""
                                                        }}
                                                    >
                                                        Community{" "}
                                                        <sup
                                                            style={{
                                                                color: global.mobileView
                                                                    ? "white"
                                                                    : "#0038FF",
                                                            }}
                                                        >
                                                            <Label t_size="0.44vw" size="2vw" medium>
                                                                COMING SOON
                                                            </Label>
                                                        </sup>
                                                    </Link>
                                                </Dropdown>
                                                {global.mobileView && <Hr style={{marginLeft: "10vw"}} />}
                                            </Content>
                                        )}
                                        <Link
                                            href="https://apps.apple.com/gb/app/docme/id1516996141"
                                            pl="8vw"
                                            t_pl="0"
                                            display="none"
                                            t_display="flex"
                                            alignItems="center"
                                        >
                                            <Button bgColor="#0038FF" borderRadius="500px" w="11vw" ml="-10%">
                                                <Label
                                                    bold
                                                    fontColor="#fff"
                                                    t_py="0.6vw"
                                                    py="1.6vw"
                                                    t_px="0.8vw"
                                                    px="2vw"
                                                    size="4vw"
                                                    t_size="0.9vw"
                                                >
                                                    Signup today
                                                </Label>
                                            </Button>
                                        </Link>
                                    </Content>
                                </Content>
                            )}
                        </Content>
                    </Content>
                </Content>
            </>
            <CookieBannerDocme />
        </>
    );
};

const Hr = styled.hr`
    border: 0;
    height: 0;
    width: 85vw;
    border-top: 1px solid rgba(255, 255, 255, 0.45);
`;

export default NavbarDocMe;
