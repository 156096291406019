import React, { lazy, memo, Suspense, useEffect, useState } from "react";
import { Switch, Route, withRouter } from "react-router";
import Loading from "../templates/Loading";
function camelize(str) {
    return str
        .replace(/(^[a-z])|(-[a-zA-Z0-9])/g, function (match, index) {
            return match.toUpperCase();
        })
        .replace(/-/g, "");
}
const LazyLoad = memo(
    (props) => {
        const [Screen, setScreen] = useState(null);
        const {
            match: { params },
        } = props;
        useEffect(() => {
            switch (true) {
                case !params.path1:
                    const screenIndex = lazy(() => import(`../templates/Docme`));
                    setScreen(screenIndex);
                    break;
                default:
                    const paths = Object.values(params).filter((x) => x !== undefined);
                    const combinePath = paths
                        .map((path, i) =>
                            paths.length === i + 1 ? camelize(path.toLowerCase()) : path.toLowerCase()
                        )
                        .join("/");
                    const dynamicScreenPath = lazy(() => import(`../templates/${combinePath}`));
                    setScreen(dynamicScreenPath);
                    break;
            }
        }, [setScreen, params]);

        return <Suspense fallback={<Loading />}>{Screen ? <Screen /> : <Loading />}</Suspense>;
    },
    (p, n) => p.location.pathname === n.location.pathname
);
const Routes = ({ location, location: { pathname } }) => (
    <Switch location={location}>
        <Route
            sensitive={false}
            path={`/:path1?/:path2?/:path3?`}
            render={(props) => <LazyLoad key={pathname} {...props} />}
        />
    </Switch>
);
export default withRouter(Routes);
