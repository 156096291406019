export const colors =  {

    black: "#000",

    white: "#fff",

    primary: "#0032ff",
    secondary: "#e2f1ff",
    
    lightgray: "#e2e8f0",
    gray: "#a0aec0",
    darkgray: "#4a5568",

    lightred: "#feb2b2",
    red: "#f56565",
    darkred: "#c53030",

    lightorange: "#fbd38d",
    orange: "#ed8936",
    darkorange: "#c05621",

    lightyellow: "#faf089",
    yellow: "#ecc94b",
    darkyellow: "#b7791f",

    lightgreen: "#9ae6b4",
    green: "#48bb78",
    darkgreen: "#2f855a",

    lightteal: "#81e6d9",
    teal: "#38b2ac",
    darkteal: "#2c7a7b",

    lightblue: "#90cdf4",
    blue: "#4299e1",
    darkblue: "#2b6cb0",
    
    lightindigo: "#a3bffa",
    indigo: "#667eea",
    darkindigo: "#4c51bf",
    
    lightpurple: "#d6bcfa",
    purple: "#9f7aea",
    darkpurple: "#6b46c1",
    
    lightpink: "#fbb6ce",
    pink: "#ed64a6",
    darkpink: "#b83280",

}