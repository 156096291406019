import styled from "styled-components";

import { screens } from "../helpers/device";
import { css, cssRem, cssBool } from "../helpers/sizes";

const Label = styled.label`
    ${(p) => css(p["font"], "font-family", "'Montserrat', sans-serif")}
    ${(p) => css(p["fontColor"], "color", "black")}
    ${(p) => css(p["fontStyle"], "font-style")}
    ${(p) => css(p["bgColor"], "background-color", "black")}
    ${(p) => css(p["cursor"], "cursor")}
    ${(p) => css(p["decoration"], "text-decoration")}
    ${(p) => css(p["position"], "position")}
    ${(p) => css(p["borderRadius"], "border-radius")}
    ${(p) => css(p["border"], "border")}
    ${(p) => css(p["opacity"], "opacity")}
    
    ${(p) => cssBool(p["xBold"], "font-weight: 800")}
    ${(p) => cssBool(p["bold"], "font-weight: 700")}
    ${(p) => cssBool(p["italic"], "font-style: italic")}
    ${(p) => cssBool(p["bolder"], "font-weight: 600")}
    ${(p) => cssBool(p["medium"], "font-weight: 500")}
    ${(p) => cssBool(p["normal"], "font-weight: 400")}
    ${(p) => cssBool(p["noWrap"], "white-space: no-wrap")}
    ${(p) => cssBool(p["preLine"], "white-space: pre-line")}
    ${(p) => cssBool(p["breakSpaces"], "white-space: break-spaces")}
    ${(p) => cssBool(p["noSelect"], "user-select: none")}

    ${screens.map((s) => {
        return (p) => `@media only screen and (min-width:${s.min}) {
            ${css(p[s.id + "size"], "font-size", "14px")}
            ${css(p[s.id + "align"], "text-align")}
            ${css(p[s.id + "alignItems"], "align-items")}
            ${css(p[s.id + "lineHeight"], "line-height")}
            ${css(p[s.id + "display"], "display")}
            ${css(p[s.id + "flexDirection"], "flex-direction")}
            ${css(p[s.id + "justifyContent"], "justify-content")}

            ${cssRem(p[s.id + "w"], "width")}
            ${cssRem(p[s.id + "h"], "height")}
            
            ${cssRem(p[s.id + "borderRadius"], "border-radius")}
            ${css(p[s.id + "zIndex"], "z-index")}

            ${cssRem(p[s.id + "p"], "padding")}
            ${cssRem(p[s.id + "pt"] || p[s.id + "py"], "padding-top")}
            ${cssRem(p[s.id + "pr"] || p[s.id + "px"], "padding-right")}
            ${cssRem(p[s.id + "pb"] || p[s.id + "py"], "padding-bottom")}
            ${cssRem(p[s.id + "pl"] || p[s.id + "px"], "padding-left")}

            ${cssRem(p[s.id + "m"], "margin")}
            ${cssRem(p[s.id + "mt"] || p[s.id + "my"], "margin-top")}
            ${cssRem(p[s.id + "mr"] || p[s.id + "mx"], "margin-right")}
            ${cssRem(p[s.id + "mb"] || p[s.id + "my"], "margin-bottom")}
            ${cssRem(p[s.id + "ml"] || p[s.id + "mx"], "margin-left")}

        }`;
    })}
`;

export default Label;
