import React, { useState } from "react";
import styled from "styled-components";

import planText from "./planText";

import Content from "../../atoms/Content";
import Label from "../../atoms/Label";

import imgIconForMe from "../../../assets/images/icons/icon_24px_for_me_blue.svg";
import imgIconForDoctors from "../../../assets/images/icons/icon_24px_for_doctors_blue.svg";
import Link from "../../atoms/Link";
import Image from "../../atoms/Image";

import plusIcon from "../../../assets/images/docme/add--filled.svg";
import crossIcon from "../../../assets/images/docme/close--filled.svg";

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    AccordionItemState,
} from "react-accessible-accordion";
import HealthPlan from "../healthPlan/HealthPlan";

const Plan = () => {
    const [showPlan, setShowPlan] = useState(1);

    const [borderBottom, setBorderBottom] = useState({
        one: true,
        two: false,
    });

    // Created to circumvent working accordion parts
    const freePlanText = [
        {
            header: "Heart Rate Variability (HRV)",
        },
        // {
        //     header: "Blood Oxygen",
        // },
        {
            header: "Blood Pressure",
        },
        // {
        //     header: "Skin Temperature",
        // },
    ];

    return (
        <Content w="100vw" mb="20vw" t_mb="2vw" t_pb="5vw" display="flex" flexDirection="column">
            <Content t_mt="0vw" mt="10vw" mb="4vw">
                <PlanBox
                    display="flex"
                    t_flexDirection="row"
                    flexDirection="row"
                    alignItems="center"
                    t_alignItems="end"
                >
                    <Content
                        display="flex"
                        flexDirection="column"
                        t_w="33.5vw"
                        w="40%"
                        t_mt="0.5vw"
                    >
                        <Label
                            bolder
                            fontColor={showPlan == 1 ? "black" : "#4F6E8C"}
                            align="center"
                            w="31vw"
                            t_w="31vw"
                            t_size="0.8vw"
                            size="2.5vw"
                            t_display="flex"
                            display="flex"
                            mx="8vw"
                            t_mx="20vw"
                        >
                            FREE PLAN
                        </Label>
                        <Label
                            bolder
                            fontColor={showPlan == 1 ? "black" : "#4F6E8C"}
                            align="center"
                            w="31vw"
                            t_w="31vw"
                            t_size="2vw"
                            size="5.8vw"
                            t_display="flex"
                            display="flex"
                            mx="8vw"
                            t_mx="20vw"
                            decoration="underline"
                            style={{ textUnderlineOffset: "0.35vw", cursor: "pointer" }}
                            onClick={() => setShowPlan(1)}
                        >
                            Wellness
                        </Label>
                    </Content>

                    <Content>
                        <Vr />
                    </Content>

                    <Content display="flex" flexDirection="column" t_mt="0.5vw">
                        <Label
                            bolder
                            fontColor={showPlan == 2 ? "black" : "#667093"}
                            align="center"
                            w="40vw"
                            t_w="32vw"
                            t_size="0.8vw"
                            size="2.5vw"
                            t_display="flex"
                            display="flex"
                            mx="5vw"
                            t_mx="0vw"
                            t_pt="1vw"
                            pt="1.5vw"
                        >
                            {" "}
                        </Label>
                        {/* <br style={{ display: "none" }} /> */}
                        <Label
                            bolder
                            fontColor={showPlan == 2 ? "black" : "#667093"}
                            align="center"
                            w="40vw"
                            t_w="31vw"
                            t_size="2vw"
                            size="5.8vw"
                            t_display="flex"
                            display="flex"
                            mx="5vw"
                            t_mx="0vw"
                            decoration="underline"
                            style={{ textUnderlineOffset: "0.35vw", cursor: "pointer" }}
                            onClick={() => setShowPlan(2)}
                        >
                            Health
                        </Label>
                    </Content>
                </PlanBox>
                
            </Content>

            {showPlan == 1 ? (
                <Content mx="8vw" t_mx="20vw">
                    <Label
                        bolder
                        align="left"
                        w="90vw"
                        t_w="40vw"
                        t_size="2.5vw"
                        size="7vw"
                        t_display="block"
                        display="block"
                        t_mb="8vw"
                        mb="8vw"
                        fontColor="#001242"
                    >
                        Checking your vital signs
                        <span>
                            <sup style={{ color: "#667093" }}>
                                <Label t_size="0.8vw" size="2.5vw" bolder>
                                    3
                                </Label>
                            </sup>
                        </span>{" "}
                        is free. Forever.
                    </Label>
                    {planText.map((item, i) => (
                        <Content key={i} mb="0vw">
                            {/*<Hr style={{ marginTop: "1vw" }} />*/}
                            <Accordion allowMultipleExpanded allowZeroExpanded>
                                <Content t_mb="0vw" mb="0vw" ml="-2vw">
                                    {item.section.map((sectionItem) => (
                                        <AccordionItem key={sectionItem.id}>
                                            <Content t_my="2vw" my="4vw" t_mb="6vw">
                                                <AccordionItemHeading style={{ cursor: "pointer" }}>
                                                    <AccordionItemButton>
                                                        <Content
                                                            display="flex"
                                                            flexDirection="row"
                                                            alignItems="center"
                                                        >
                                                            <Label
                                                                cursor="pointer"
                                                                bolder
                                                                t_size="1.8vw"
                                                                size="5vw"
                                                                mt="-7%"
                                                            >
                                                                {sectionItem.question}{" "}
                                                                <sup style={{ color: "#667093" }}>
                                                                    <Label
                                                                        t_size="1.1vw"
                                                                        size="2.5vw"
                                                                        bolder
                                                                    >
                                                                        {sectionItem.extra}
                                                                    </Label>
                                                                </sup>
                                                            </Label>
                                                            <AccordionItemState>
                                                                {({ expanded }) =>
                                                                    !expanded ? (
                                                                        <Image
                                                                            w="7vw"
                                                                            t_w="1.8vw"
                                                                            ml="auto"
                                                                            src={plusIcon}
                                                                            alt=""
                                                                            mt="-6%"
                                                                        />
                                                                    ) : (
                                                                        <Image
                                                                            w="7vw"
                                                                            t_w="1.8vw"
                                                                            ml="auto"
                                                                            src={crossIcon}
                                                                            alt=""
                                                                            mt="-6%"
                                                                        />
                                                                    )
                                                                }
                                                            </AccordionItemState>
                                                        </Content>
                                                        <Hr />
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <Content t_mt="1vw" mt="8vw" w="85%" t_w="90%">
                                                        <Label
                                                            medium
                                                            t_size="1.1vw"
                                                            size="3.6vw"
                                                            t_lineHeight="2vw"
                                                            lineHeight="6vw"
                                                        >
                                                            {sectionItem.answer}
                                                        </Label>
                                                    </Content>
                                                </AccordionItemPanel>
                                            </Content>
                                        </AccordionItem>
                                    ))}
                                </Content>
                            </Accordion>
                        </Content>
                    ))}
                </Content>
            ): <HealthPlan />}
        </Content>
    );
};

const Hr = styled.hr`
    border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
`;

const Vr = styled.hr`
    border: 0;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    border-right: 1px solid rgba(255, 255, 255, 0.3);
    height: 3vw;
    margin-right: 4.5vw;
    @media only screen and (max-width: 426px) {
        height: 11vw;
        margin-right: 0.5vw;
    }
`;

const LabelPartition = styled(Label)`
    @media only screen and (max-width: 600px) {
        content: "";
    }
`;

const PlanBox = styled(Content)`
    overflow-x: auto;
    &::-webkit-scrollbar {
        display: none;
    }
    scrollbar-width: none;
    -ms-overflow-style: none;
`;

export default Plan;
