import React, {useContext, useState} from "react"
import styled from "styled-components"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons"

import imgAppStore from "../../assets/images/docme/App_store_badge – 2022.svg";
import imgGooglePlay from "../../assets/images/GooglePlay.svg";
import imgIco from "../../assets/images/docme_redesigned/ico_logo_footer.png"
import imgNhs from "../../assets/images/docme_redesigned/NHS_logo_footer.png"

import Content from "../atoms/Content"
import Label from "../atoms/Label"
import Link from "../atoms/Link"
import Image from "../atoms/Image"

import Social from "../molecules/Social"
import {GlobalContext} from "../../App";


const Footer = () => {
   const global = useContext(GlobalContext);

   const [expand, setExpand] = useState(false)

   return (
      <Content bgColor="#f0f5f7" w="100%">
         <ContentBorder minH="70" t_mx="5%" mx="8%" py="10" display="flex" flexDirection="column-reverse">
            <Content display="flex" flexDirection="column">
            <Content mb="6vw" t_mb="2vw" t_ml="auto">
               <Social w="78vw" t_w="18vw" fontColor="#667093" />
            </Content>
            <Content display="flex" flexDirection="column" t_flexDirection="row" justifyContent="center" t_justifyContent="space-between">
               <Content mb="6vw" t_mb="0" display="flex" justifyContent="space-between" t_justifyContent="flex-start">
                  <Content w="40vw" t_w="12vw" display="flex" flexDirection="column">
                     <Label bolder size="5vw" t_size="1vw" fontColor="#667093" mb="1vw">About DocMe</Label>
                     {/* <Link href="/values" size="3.8vw" t_size="0.8vw" fontColor="#667093" mb="1.5vw" t_mb="0.4vw">Perfexia Values</Link> */}
                     <Link href="/#howDocmeWorks" medium size="3.8vw" t_size="0.8vw" fontColor="#667093" mb="1.5vw" t_mb="0.4vw">How DocMe works</Link>
                     <Link href="/our-technology" medium size="3.8vw" t_size="0.8vw" fontColor="#667093" mb="1.5vw" t_mb="0.4vw">Technology</Link>
                     <Link href="/newsroom" medium size="3.8vw" t_size="0.8vw" fontColor="#667093" mb="1.5vw" t_mb="0.4vw">Newsroom</Link>
                     <Link href="/company-overview" medium size="3.8vw" t_size="0.8vw" fontColor="#667093" mb="1.5vw" t_mb="0.4vw">About us</Link>
                     {/*<Link href="/strong-privacy" size="3.8vw" t_size="0.8vw" fontColor="#667093" mb="1.5vw" t_mb="0.4vw">Privacy</Link> */}
                     {/* <Link href="/docme/help" size="3.8vw" t_size="0.8vw" fontColor="#667093" mb="1.5vw" t_mb="0.4vw">Help centre</Link> */}
                  </Content>
                  <Content w="35vw" t_w="12vw" display="flex" flexDirection="column">
                     <Label bolder size="5vw" t_size="1vw" fontColor="#667093" mb="1vw">Partners</Label>
                     <Link href="https://jbs.cam.ac.uk/" medium target="_blank" size="3.8vw" t_size="0.8vw" fontColor="#667093" mb="1.5vw" t_mb="0.4vw">Cambridge Judge</Link>
                     <Link href="https://jbs.cam.ac.uk/" medium target="_blank" size="3.8vw" t_size="0.8vw" fontColor="#667093" mb="1.5vw" t_mb="0.4vw">Business School</Link>
                     <Link href="https://surgicalmic.nihr.ac.uk/medtechfoundation/" medium target="_blank" size="3.8vw" t_size="0.8vw" fontColor="#667093" mb="1.5vw" t_mb="0.4vw">MedTech Foundation</Link>
                     <Link href="http://binformed.info/" target="_blank" size="3.8vw" medium t_size="0.8vw" fontColor="#667093" mb="1.5vw" t_mb="0.4vw">binformed | covidata</Link>
                      <Link href="https://dellmed.utexas.edu/" target="_blank" medium size="3.8vw" t_size="0.8vw" fontColor="#667093" mb="1.5vw" t_mb="0.4vw">Dell Medical School</Link>
                      <Link href="" medium target="_blank" size="3.8vw" t_size="0.8vw" fontColor="#667093" mb="1.5vw" t_mb="0.4vw">Panacea</Link>
                  </Content>
               </Content>
               <Content mb="6vw" display="flex" justifyContent="space-between" t_justifyContent="flex-start">
                  {/*<Content w="35vw" t_w="12vw" display="flex" flexDirection="column">*/}
                  {/*   <Label medium size="5vw" t_size="1vw" fontColor="#667093" mb="3vw" t_mb="1vw">Technology</Label>*/}
                  {/*   <Link href="/our-technology" size="3.8vw" t_size="0.8vw" fontColor="#667093" mb="1.5vw" t_mb="0.4vw">SmartVisions</Link>*/}
                  {/*   /!* <Link href="/more" size="3.8vw" t_size="0.8vw" fontColor="#667093" mb="1.5vw" t_mb="0.4vw">More</Link> *!/*/}
                  {/*</Content>*/}
                  <Content w="35vw" t_w="12vw" display="flex" flexDirection="column">
                     <Label bolder size="5vw" t_size="1vw" fontColor="#667093" mb="1vw">Help</Label>
                      <Link href="/docme/help" size="3.8vw" medium t_size="0.8vw" fontColor="#667093" mb="1.5vw" t_mb="0.4vw">Help centre</Link>
                     <Link href="" size="3.8vw" t_size="0.8vw" medium fontColor="#667093" mb="1.5vw" t_mb="0.4vw"> Support forum</Link>
                     <Link href="" size="3.8vw" t_size="0.8vw" medium fontColor="#667093" mb="1.5vw" t_mb="0.4vw">(Coming Soon)</Link>
                  </Content>
                  <Content w="35vw" t_w="12vw" display="flex" flexDirection="column">
                     <Label bolder size="5vw" t_size="1vw" fontColor="#667093" mb="3vw" t_mb="1vw">Product</Label>
                     <Link href="/" target="_blank" medium size="3.8vw" t_size="0.8vw" fontColor="#667093">The DocMe App</Link>
                     <Content
                        display="flex"
                        flexDirection="column"
                        mt="0.8vw"
                     >
                        <Image src={imgAppStore} w="30vw" t_w="7.5vw" style={{cursor: "pointer"}}
                           onClick={(e) => {
                              e.preventDefault();
                              window.location.href="https://apps.apple.com/gb/app/docme/id1516996141";
                           }}
                        />
                        {/*<Image src={imgGooglePlay} w="30vw" t_w="7.5vw" mt="0.8vw" style={{ cursor: "pointer"}} onClick={e => {*/}
                        {/*   e.preventDefault()*/}
                        {/*   window.location.href = "https://play.google.com/store/apps/details?id=com.perfexia_docme"*/}
                        {/*}} />*/}
                        <Label t_size="0.8vw" size="2vw" medium mt="0.5vw"
                               style={{
                           color: global.mobileView
                               ? "white"
                               : "#0038FF",
                        }}>
                        COMING SOON
                     </Label>

                    </Content>
                  </Content>
               </Content>
               <Content mb="6vw" display="flex" justifyContent="space-between" t_justifyContent="flex-start">
                  <Content w="35vw" t_w="11vw" display="flex" flexDirection="column">
                     <Label bolder size="5vw" t_size="1vw" fontColor="#667093" mb="3vw" t_mb="1vw">Legal</Label>
                     <Link href="https://docme-tech.slite.page/p/Ic-uVTQimGPnPR/The-DocMe-App-Privacy-Policy" target="_blank" medium size="3.8vw" t_size="0.8vw" fontColor="#667093" mb="1.5vw" t_mb="0.4vw">Privacy Policy</Link>
                     <Link href="https://docme-tech.slite.page/p/p1b7oK_jcqB7Ji/The-DocMe-App-EULA" target="_blank" medium size="3.8vw" t_size="0.8vw" fontColor="#667093" mb="1.5vw" t_mb="0.4vw">EULA</Link>
                     <Link href="/docme/regulatory" medium size="3.8vw" t_size="0.8vw" fontColor="#667093" mb="1.5vw" t_mb="0.4vw">Regulatory</Link>
                     {/* <Link href="/cookie-policy" size="3.8vw" t_size="0.8vw" fontColor="#667093" mb="1.5vw" t_mb="0.4vw">Cookie Policy</Link> */}
                  </Content>
                  <Content w="35vw" t_w="10.4vw" display="flex" flexDirection="column">
                     {/*<Content onClick={() => {setExpand(!expand)}} flexDirection="row" display="flex" >*/}
                     {/*   <Label mt="0.5vw" t_size="1vw" fontColor="#667093" mr="0.5w"><FontAwesomeIcon icon={ expand ? faMinus : faPlus } /></Label>*/}
                     {/*   <Label medium size="5vw" t_size="1vw" size="4.2vw" fontColor="#667093" ml="2vw" t_ml="0.5vw" mb="3vw" t_mb="1vw">For Employees & Partner Clinicians</Label>*/}
                     {/*</Content>*/}
                     {/*   <Link href="https://portal.office.com" ml="5.2vw" t_ml="1.4vw" display={ expand ? "block" : "none"} target="_blank" size="3.8vw" t_size="0.8vw" fontColor="#667093" mb="1.5vw" t_mb="0.4vw">Employees</Link>*/}
                     {/*   <Link href="https://doctors.perfexia.health" ml="5.2vw" t_ml="1.4vw" display={ expand ? "block" : "none"} size="3.8vw" t_size="0.8vw" fontColor="#667093" mb="1.5vw" t_mb="0.4vw">Partner Clinicians</Link>*/}
                  </Content>
               </Content>
               <Content mb="5vw" t_ml="2.5vw" display="flex" justifyContent="space-between" t_justifyContent="flex-start">
                  <Content w="35vw" t_w="18vw" display="flex" flexDirection="column">
                     <Label medium size="5vw" t_size="1.5vw" fontColor="#667093" mb="3vw" t_mb="1vw">Meet us</Label>
                     <Label size="3.8vw" medium t_size="0.8vw" fontColor="#667093" mb="1.5vw" t_mb="0.1vw">Cambridge Judge Business School,</Label>
                     <Label size="3.8vw" medium t_size="0.8vw" fontColor="#667093" mb="1.5vw" t_mb="0.1vw">Entrepreneurship Centre,</Label>
                     <Label size="3.8vw" medium t_size="0.8vw" fontColor="#667093" mb="1.5vw" t_mb="0.1vw">Trumpington Street,</Label>
                     <Label size="3.8vw" medium t_size="0.8vw" fontColor="#667093" mb="1.5vw" t_mb="0.1vw">Cambridge</Label>
                     <Label size="3.8vw" medium t_size="0.8vw" fontColor="#667093" mb="1.5vw" t_mb="0.1vw">CB2 1AG UK</Label>
                  </Content>
               </Content>
            </Content>
                  <Content
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      flexDirection={global.mobileView ? "column" : ""}
                  >
                     <Content display="flex" flexDirection="column">
                        <Label medium size="3.8vw" t_size="12px" fontColor="#667093" mb="10px">DocMe® is a registered trademark of DOCME TECHNOLOGIES LTD, and/or its subsidiaries and/or affiliates in the UK and/or other countries.</Label>
                        <Label medium size="3.8vw" t_size="12px" fontColor="#667093">© 2023 DocMe TECHNOLOGIES LTD</Label>
                     </Content>
                     <Content>
                        <Link href="https://ico.org.uk/ESDWebPages/Entry/ZB197766" target="_blank">
                           <Image mr="50px" w="64px" src={imgIco}/>
                        </Link>
                        <Image w="64px" src={imgNhs}/>
                     </Content>
                  </Content>
            </Content>
         </ContentBorder>
      </Content>
   )
}

const ContentBorder = styled(Content)`
   border-top: 1px solid rgba(0,0,0,.1);
`

export default Footer