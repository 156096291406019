//gsap
import { gsap, ScrollTrigger } from "gsap/all";
import { Expo } from "gsap/gsap-core";
import imgPhone1 from "../../assets/images/docme/Scroll_Transition_1.png";
import imgPhone2 from "../../assets/images/docme/How_does_it_work_2.png";
import imgPhone3 from "../../assets/images/docme/How_does_it_work_3.png";
import imgPhone4 from "../../assets/images/docme/How_does_it_work_4.png";

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger);
  gsap.core.globals("ScrollTrigger", ScrollTrigger);
}

export const phoneTransition = (trigger, node, node2) => {
  let tlSec = gsap.timeline({
    scrollTrigger: {
      // markers: true,
      trigger: trigger,
      start: "top top",
      end: "bottom bottom",
      scrub: true,
    },
  });

  tlSec
    .set(node, {
      position: "fixed",
      zIndex: 99,
      ease: Expo.easeIn,
    })
    .to(node, {
      display: "none",
    });

  let tlSec2 = gsap.timeline({
    scrollTrigger: {
      // markers: true,
      trigger: ".targetScreen2",
      start: "top top",
      end: "top top",
      scrub: true,
    },
  });

  tlSec2.to(node2, {
    attr: {
      src: imgPhone2,
    },
  });

  let tlSec3 = gsap.timeline({
    scrollTrigger: {
      // markers: true,
      trigger: ".targetScreen3",
      start: "top top",
      end: "top top",
      scrub: true,
    },
  });

  tlSec3.to(node2, {
    attr: {
      src: imgPhone3,
    },
  });

  let tlSec4 = gsap.timeline({
    scrollTrigger: {
      // markers: true,
      trigger: ".targetScreen4",
      start: "top top",
      end: "top top",
      scrub: true,
    },
  });

  tlSec4.to(node2, {
    attr: {
      src: imgPhone4,
    },
  });

  ScrollTrigger.refresh(true);
};
