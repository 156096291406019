import React, { createContext, useEffect, useState } from "react";
import { Router } from "react-router";

import history from "./components/helpers/history";

import Routes from "./components/atoms/Routes";
import Footer from "./components/organisms/Footer";
import FooterForMe from "./components/organisms/FooterForMe";

export const GlobalContext = createContext();

const App = () => {
    const [mobileView, setMobileView] = useState(window.innerWidth < 426);
    const [footer, setFooter] = useState(true); // Footer is not needed for newsletters
    const [footerDocme, setFooterDocme] = useState(false);

    const onResize = (e) => {
        let isProcess = false;
        if (isProcess) return;
        isProcess = true;
        requestAnimationFrame(() => {
            setMobileView(window.innerWidth < 426);
            isProcess = false;
        });
    };


    useEffect(() => {
        window.addEventListener("resize", onResize)
        var pathName = (window.location.pathname).split('-')[1]
        var pathForm = (window.location.pathname).split('/')[2] //Form for clinical trials

        if(pathName == 'update' || pathForm == 'form' ){
            setFooter(false)
        }

        return () => {
            window.removeEventListener("resize", onResize);
        }
    }, [])

    return (
        <GlobalContext.Provider value={{ mobileView }}>
            <Router sensitive={false} history={history}>
                <div>
                     <Routes />
                    {footer && <Footer />}
                    {footerDocme && <FooterForMe />}
                </div>
            </Router>
        </GlobalContext.Provider>
    );
};

export default App;