import React from 'react';
import Content from '../../atoms/Content';
import styled from "styled-components";

import imgBP from '../../../assets/images/docme/Manage_your_Blood Pressure.png';
import imgStress from '../../../assets/images/docme/Manage_your_stress.png';
import imgContraception from '../../../assets/images/docme/Get_your_contraception_plan_online.png';
import imgWorkout from '../../../assets/images/docme/Get_active_with_workout tracking.png';
import Label from '../../atoms/Label';

import imgArrowRightBlack from "../../../assets/images/docme_redesigned/arrow_right_black.svg";
import Image from '../../atoms/Image';

const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: false,
    autoplaySpeed: 4000,
  };

const AssesmentCard = () => {
    const cards = [
        {
            title: "Manage your Blood Pressure",
            paragraph: "1 in 4 people worldwide have clinically elevated blood pressure, a condition often referred to as the “silent killer” because it is asymptomatic. To effectively manage blood pressure, the first crucial step is monitoring it regularly. Start by taking a free assessment today to gain valuable insights into your blood pressure status and learn more about how to manage it effectively.",
            img: imgBP,
            state: "exist"
        },
        {
            title: "Get your contraception plan online",
            paragraph: "DocMe offers a convenient and accessible way to get contraception plans on the app. It involves assessing your blood pressure and other cardiovascular health concerns, providing personalized advice, and access to contraception options without in-person visits. DocMe enables you to manage your reproductive health conveniently, privately, and from home.",
            img: imgContraception,
            state:"comingsoon"
        },
        {
            title: `Get active with workout tracking`,
            paragraph: "The DocMe app creates a personalized data-driven workout plan for a tailored exercise experience. By analyzing your cardiovascular health, fitness levels, body composition, and exercise preferences, the app customizes workout plans to optimize results. It targets specific areas for improvement, adapts to progress, and offers a comprehensive fitness experience.",
            img: imgWorkout,
            state:"comingsoon"
        },
        {
            title: "Manage your stress",
            paragraph: "Manage stress with the DocMe app. It monitors HRV and blood pressure, giving insights into your stress levels. Use the data to make informed decisions and implement targeted stress-reduction techniques. The DocMe app empowers you to proactively address stress, promoting overall well-being.",
            img: imgStress,
            state:"comingsoon"
        }
    ]
    return(
        <Content className="AssesmentCards" t_w="80vw" w="95vw">
            <ScrollHoz
                display="flex"
                flexDirection="row"
                w="100vw"
                t_w="85vw"
                t_mb="4vw"
                t_h="32vw"
                mt="5vw"
                mb="15vw"
                t_mt="0vw"
            >
                <Content  
                    display="flex"
                    flexDirection="row"
                    t_w="75%"
                    t_mb="0vw"
                    t_h="28vw"
                    mt="5vw"
                    mb="15vw"
                    t_mt="0vw"
                >
                   {cards.map((card,i) => (
                        <Content boxShadow="0px 3px 16px #00000029" t_mx="1vw" w="75vw" mx="2vw" t_mb="0" mb="4vw" borderRadius="20px 20px 0 0">
                            <Content bgImage={`url("${card.img}")`} display="flex" textAlign="left" alignItems="flex-end" t_w="14.15vw" w="75vw" t_h="12vw" h="45vw" t_px="1.75vw"  pb="3vw" t_pb="1vw" borderRadius="20px 20px 0 0" >
                                <Label fontColor="#fff" bolder t_size="1.7vw" size="7vw" t_px="0" px="8vw" w={ i > 1 ? "50vw" : ""} t_w={ i > 1 ? "13vw" : "14.15vw" } >{card.title}</Label>
                            </Content>
                            <Content t_w="14.5vw" t_pl="2vw" t_px="0vw" px="8.5vw" t_pb="0vw" pb="3vw" display="flex" flexDirection="column" >
                                <Label  t_size="0.75vw" size="3.2vw" pt="6vw" t_pt="1vw">{card.paragraph}</Label>
                                {card.state === "exist"?
                                    <Label display="flex" fontColor="#001242" t_size="0.89vw" size="4vw" t_pb="0" pb="3vw" bold ml="auto" t_pt="1vw" pt="5vw">Take Assesment <Image t_pl="0.4vw" t_pb="0.4vw" t_w="1.3vw" t_h="1.3vw" src={imgArrowRightBlack} /></Label>
                                    :
                                    <Label display="flex" fontColor="#4F6E8C" t_size="0.89vw" size="4vw" t_pb="0" pb="3vw" bold ml="auto" t_pt="1vw" pt="5vw" >COMING SOON </Label>
                                }
                            </Content>
                        </Content>
                    ))} 
                </Content>
            </ScrollHoz>
        </Content>
    )
}

const ScrollHoz = styled(Content)`
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
`;

export default AssesmentCard;