import React from "react";

import Content from "../atoms/Content";

const Dropdown = ({ label, children, show, onClick, ...props }) => {
    return (
        <Content display="flex" flexDirection="column">
            <Content onClick={onClick} {...props}>
                {label}
            </Content>
            {show && (
                <Content position="relative">
                    <Content
                        h="auto"
                        w="11vw"
                        t_w="11.25"
                        mt="0vw"
                        t_mt="4"
                        py="0vw"
                        // t_py="4"
                        position="relative"
                        t_position="absolute"
                        t_bgColor="white"
                        bgColor="none"
                        display="flex"
                        flexDirection="column"
                        borderRadius="6px"
                        t_boxShadow="0px 6px 12px #00000048"
                        boxShadow="none"
                        t_ml="0vw"
                        ml="5vw"
                    >
                        {children}
                    </Content>
                </Content>
            )}
        </Content>
    );
};

export default Dropdown;
