import React, { useState } from "react";
import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";

import imgAppStore from "../../assets/images/docme/App_store_badge – 2022.svg";
import imgGooglePlay from "../../assets/images/GooglePlay.svg";

import Content from "../atoms/Content";
import Label from "../atoms/Label";
import Link from "../atoms/Link";
import Image from "../atoms/Image";

import Social from "../molecules/Social";
import Landing from "../templates/Main";

import imgICOfooter from "../../assets/images/docme_redesigned/ico_logo_footer.png";
import imgNHSfooter from "../../assets/images/docme_redesigned/NHS_logo_footer.png";

const Footer = () => {
    const [expand, setExpand] = useState(false);

    return (
        <Content bgColor="#f0f5f7" w="100%">
            <ContentBorder
                minH="70"
                t_mx="5%"
                mx="8%"
                py="10"
                display="flex"
                flexDirection="column-reverse"
                t_flexDirection="row"
                t_justifyContent="space-between"
            >
                <Content display="flex" flexDirection="column" t_mx="auto">
                    <Content mb="6vw" t_mb="6vw" t_ml="auto">
                        <Social w="78vw" t_w="18.5vw" fontColor="#667093" />
                    </Content>

                    <Content
                        display="flex"
                        flexDirection="column"
                        t_flexDirection="row"
                        justifyContent="center"
                        t_justifyContent="flex-start"
                    >
                        <Content
                            mb="6vw"
                            t_mb="0"
                            display="flex"
                            justifyContent="space-between"
                            t_justifyContent="flex-start"
                        >
                            <Content
                                w="40vw"
                                t_w="10vw"
                                t_mr="2vw"
                                display="flex"
                                flexDirection="column"
                            >
                                <Label medium size="5vw" t_size="1vw" fontColor="#667093" mb="1vw">
                                    About DocMe
                                </Label>
                                <Link
                                    href="/docme"
                                    size="3.8vw"
                                    t_size="0.8vw"
                                    fontColor="#667093"
                                    mb="1.5vw"
                                    t_mb="0.4vw"
                                >
                                    How DocMe works
                                </Link>
                                <Link
                                    href="/docme/our-technology"
                                    size="3.8vw"
                                    t_size="0.8vw"
                                    fontColor="#667093"
                                    mb="1.5vw"
                                    t_mb="0.4vw"
                                >
                                    Technology
                                </Link>
                                {/* <Link
                                    href="/docme/more"
                                    size="3.8vw"
                                    t_size="0.8vw"
                                    fontColor="#667093"
                                    mb="1.5vw"
                                    t_mb="0.4vw"
                                >
                                    More
                                </Link> */}

                                <Link
                                    href="/docme/newsroom"
                                    size="3.8vw"
                                    t_size="0.8vw"
                                    fontColor="#667093"
                                    mb="1.5vw"
                                    t_mb="0.4vw"
                                >
                                    Newsroom
                                </Link>
                                <Link
                                    href="/company-overview"
                                    size="3.8vw"
                                    t_size="0.8vw"
                                    fontColor="#667093"
                                    mb="1.5vw"
                                    t_mb="0.4vw"
                                >
                                    Company overview
                                </Link>
                            </Content>

                            <Content
                                w="35vw"
                                t_w="10.5vw"
                                t_mr="2vw"
                                display="flex"
                                flexDirection="column"
                            >
                                <Label medium size="5vw" t_size="1vw" fontColor="#667093" mb="1vw">
                                    Partners
                                </Label>
                                <Link
                                    href="https://jbs.cam.ac.uk/"
                                    target="_blank"
                                    size="3.8vw"
                                    t_size="0.8vw"
                                    fontColor="#667093"
                                    mb="1.5vw"
                                    t_mb="0.4vw"
                                >
                                    Cambridge Judge Business School
                                </Link>
                                <Link
                                    href="https://surgicalmic.nihr.ac.uk/medtechfoundation/"
                                    target="_blank"
                                    size="3.8vw"
                                    t_size="0.8vw"
                                    fontColor="#667093"
                                    mb="1.5vw"
                                    t_mb="0.4vw"
                                >
                                    MedTech Foundation
                                </Link>
                                <Link
                                    href="http://binformed.info/"
                                    target="_blank"
                                    size="3.8vw"
                                    t_size="0.8vw"
                                    fontColor="#667093"
                                    mb="1.5vw"
                                    t_mb="0.4vw"
                                >
                                    Binformed | Covidata
                                </Link>
                                {/* <Link
                                    href="https://dellmed.utexas.edu/"
                                    target="_blank"
                                    size="3.8vw"
                                    t_size="0.8vw"
                                    fontColor="#667093"
                                    mb="1.5vw"
                                    t_mb="0.4vw"
                                >
                                    Dell Medical School
                                </Link>
                                <Link
                                    href="https://www.cuh.nhs.uk/clinical-trials/cambridge-clinical-trials-unit-cctu"
                                    target="_blank"
                                    size="3.8vw"
                                    t_size="0.8vw"
                                    fontColor="#667093"
                                    mb="1.5vw"
                                    t_mb="0.4vw"
                                >
                                    CCTU
                                </Link> */}
                            </Content>
                        </Content>

                        <Content
                            mb="6vw"
                            display="flex"
                            justifyContent="space-between"
                            t_justifyContent="flex-start"
                        >
                            <Content w="35vw" t_w="12vw" display="flex" flexDirection="column">
                                <Label
                                    medium
                                    size="5vw"
                                    t_size="1vw"
                                    fontColor="#667093"
                                    mb="3vw"
                                    t_mb="1vw"
                                >
                                    Help
                                </Label>

                                <Link
                                    href="/docme/help"
                                    size="3.8vw"
                                    t_size="0.8vw"
                                    fontColor="#667093"
                                    mb="1.5vw"
                                    t_mb="0.4vw"
                                >
                                    Help centre
                                </Link>
                                <Link
                                    href=""
                                    size="3.8vw"
                                    t_size="0.8vw"
                                    fontColor="#667093"
                                    mb="1.5vw"
                                    t_mb="0.4vw"
                                >
                                    Community
                                </Link>
                                <Link
                                    href=""
                                    size="3.8vw"
                                    t_size="0.8vw"
                                    fontColor="#667093"
                                    mb="1.5vw"
                                    t_mb="0.4vw"
                                >
                                    (Coming Soon)
                                </Link>
                            </Content>

                            <Content
                                w="35vw"
                                t_w="10vw"
                                t_mr="2vw"
                                display="flex"
                                flexDirection="column"
                            >
                                <Label
                                    medium
                                    size="5vw"
                                    t_size="1vw"
                                    fontColor="#667093"
                                    mb="3vw"
                                    t_mb="1vw"
                                >
                                    Product
                                </Label>
                                <Link href="/docme" size="3.8vw" t_size="0.8vw" fontColor="#667093">
                                    DocMe
                                </Link>
                                <Content display="flex" flexDirection="column" mt="0.8vw">
                                    <Image
                                        src={imgAppStore}
                                        w="30vw"
                                        t_w="9vw"
                                        style={{ cursor: "pointer" }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            window.location.href =
                                                "https://apps.apple.com/gb/app/docme/id1516996141";
                                        }}
                                    />
                                    <Image src={imgGooglePlay} w="30vw" t_w="9vw" mt="0.8vw" style={{ cursor: "pointer"}} onClick={e => {
                                        e.preventDefault()
                                        window.location.href = "https://play.google.com/store/apps/details?id=com.perfexia_docme"
                                    }} />
                                </Content>
                            </Content>
                        </Content>

                        <Content
                            mb="6vw"
                            display="flex"
                            justifyContent="space-between"
                            t_justifyContent="flex-start"
                        >
                            <Content w="35vw" t_w="12vw" display="flex" flexDirection="column">
                                <Label
                                    medium
                                    size="5vw"
                                    t_size="1vw"
                                    fontColor="#667093"
                                    mb="3vw"
                                    t_mb="1vw"
                                >
                                    Legal
                                </Label>
                                <Link
                                    href="/docme/eula"
                                    size="3.8vw"
                                    t_size="0.8vw"
                                    fontColor="#667093"
                                    mb="1.5vw"
                                    t_mb="0.4vw"
                                >
                                    EULA
                                </Link>
                                <Link
                                    href="/docme/cookie-policy"
                                    size="3.8vw"
                                    t_size="0.8vw"
                                    fontColor="#667093"
                                    mb="1.5vw"
                                    t_mb="0.4vw"
                                >
                                    Cookie Policy
                                </Link>
                                <Link
                                    href="/docme/privacy-policy"
                                    size="3.8vw"
                                    t_size="0.8vw"
                                    fontColor="#667093"
                                    mb="1.5vw"
                                    t_mb="0.4vw"
                                >
                                    Privacy Policy
                                </Link>

                                <Link
                                    href="/docme/regulatory"
                                    size="3.8vw"
                                    t_size="0.8vw"
                                    fontColor="#667093"
                                    mb="1.5vw"
                                    t_mb="0.4vw"
                                >
                                    Regulatory
                                </Link>
                                <Link
                                    href="https://perfexia-data-room.s3.eu-west-2.amazonaws.com/IP%2BTech-documents/docme2.1.2/DocMe_Instructions_for_Users_V2.1.2.pdf"
                                    target="_blank"
                                    size="3.8vw"
                                    t_size="0.8vw"
                                    fontColor="#667093"
                                    mb="1.5vw"
                                    t_mb="0.4vw"
                                >
                                    Instructions for users
                                </Link>
                            </Content>
                            <Content w="35vw" t_w="18vw" display="flex" flexDirection="column">
                                <Label
                                    medium
                                    size="5vw"
                                    t_size="1.5vw"
                                    fontColor="#667093"
                                    mb="3vw"
                                    t_mb="1vw"
                                >
                                    Meet us
                                </Label>
                                <Label
                                    size="3.8vw"
                                    t_size="0.8vw"
                                    fontColor="#667093"
                                    mb="1.5vw"
                                    t_mb="0.1vw"
                                >
                                    Cambridge Judge Business School,
                                </Label>
                                <Label
                                    size="3.8vw"
                                    t_size="0.8vw"
                                    fontColor="#667093"
                                    mb="1.5vw"
                                    t_mb="0.1vw"
                                >
                                    Entrepreneurship Centre,
                                </Label>
                                <Label
                                    size="3.8vw"
                                    t_size="0.8vw"
                                    fontColor="#667093"
                                    mb="1.5vw"
                                    t_mb="0.1vw"
                                >
                                    Trumpington Street,
                                </Label>
                                <Label
                                    size="3.8vw"
                                    t_size="0.8vw"
                                    fontColor="#667093"
                                    mb="1.5vw"
                                    t_mb="0.1vw"
                                >
                                    Cambridge
                                </Label>
                                <Label
                                    size="3.8vw"
                                    t_size="0.8vw"
                                    fontColor="#667093"
                                    mb="1.5vw"
                                    t_mb="0.1vw"
                                >
                                    CB2 1AG UK
                                </Label>
                            </Content>
                        </Content>

                        <Content
                            mb="5vw"
                            // t_ml="13vw"
                            display="flex"
                            flexDirection="column"
                            justifyContent="space-between"
                            t_justifyContent="flex-start"
                        >

                            <Content
                                display="flex"
                                justifyContent="flex-start"
                                t_justifyContent="flex-end"
                            >
                                <Link href="https://ico.org.uk/ESDWebPages/Entry/ZA787355">
                                    <Image t_w="6vw" w="20vw" src={imgICOfooter} />
                                </Link>
                                <Link href="https://www.dsptoolkit.nhs.uk/OrganisationSearch/YGM1J">
                                    <Image t_w="6vw" w="20vw" src={imgNHSfooter} />
                                </Link>
                            </Content>
                        </Content>
                    </Content>
                    <ContentBorder>
                        <Content display="flex" flexDirection="column" mt="3vw" t_mt="1vw">
                            <Label size="3.8vw" t_size="0.8vw" fontColor="#667093">
                                DocMe.ai is a site operated by DOCME TECHNOLOGIES LTD.
                                ("We"). We are registered in England and Wales under company number 13475088 and have our registered office at Botanic House, 98-100 Hills Road, Cambridge, CB2 2JY UK. Our main trading address is Cambridge Judge Business School, Entrepreneurship Centre, Trumpington Street, Cambridge CB2 1AG UK. Our VAT number is GB399070263.
                            </Label>
                            <Label size="3.8vw" t_size="0.8vw" fontColor="#667093">
                                © 2023 DocMe TECHNOLOGIES LTD
                            </Label>
                        </Content>
                    </ContentBorder>
                </Content>
            </ContentBorder>
        </Content>
    );
};

const ContentBorder = styled(Content)`
    border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

export default Footer;
