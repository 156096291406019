import styled from "styled-components";

import { screens } from "../helpers/device";
import { cssRem, css } from "../helpers/sizes";

const Content = styled.div`
    ${(p) => css(p["bgImage"], "background-image")}
    ${(p) =>
        p["bgImage"]
            ? p["bgSize"]
                ? `background-size: ${p["bgSize"]}; background-position: center; background-repeat: no-repeat;`
                : `background-size: cover; background-position: center; background-repeat: no-repeat;`
            : ""}

    ${screens.map((s) => {
        return (p) => `@media (min-width:${s.min}) { 
            ${cssRem(p[s.id + "w"], "width")}
            ${cssRem(p[s.id + "h"], "height")}
            ${cssRem(p[s.id + "minW"], "min-width")}
            ${cssRem(p[s.id + "minH"], "min-height")}
            ${cssRem(p[s.id + "maxW"], "max-width")}
            ${cssRem(p[s.id + "maxH"], "max-height")}

            ${cssRem(p[s.id + "top"], "top")}
            ${cssRem(p[s.id + "right"], "right")}
            ${cssRem(p[s.id + "bottom"], "bottom")}
            ${cssRem(p[s.id + "left"], "left")}

            ${css(p[s.id + "overflow"], "overflow")}
            ${css(p[s.id + "overflowX"], "overflow-x")}
            ${css(p[s.id + "overflowY"], "overflow-y")}
            ${css(p[s.id + "position"], "position", "relative")}
            ${css(p[s.id + "display"], "display")}
            ${css(p[s.id + "flex"], "flex")}
            ${css(p[s.id + "flexWrap"], "flex-wrap")}
            ${css(p[s.id + "flexDirection"], "flex-direction")}
            ${css(p[s.id + "flexWrap"], "flex-wrap")}
            ${css(p[s.id + "justifyContent"], "justify-content")}
            ${css(p[s.id + "alignItems"], "align-items")}
            ${css(p[s.id + "alignSelf"], "align-self")}
            ${css(p[s.id + "boxShadow"], "box-shadow")}
            ${css(p[s.id + "borderRadius"], "border-radius")}
            ${css(p[s.id + "border"], "border")}
            ${css(p[s.id + "bgColor"], "background-color")}
            ${css(p[s.id + "bg"], "background")}
            ${css(p[s.id + "zIndex"], "z-index")}
            ${css(p[s.id + "textAlign"], "text-align")}
            ${css(p[s.id + "cursor"], "cursor")}

            ${cssRem(p[s.id + "p"], "padding")}
            ${cssRem(p[s.id + "pt"] || p[s.id + "py"], "padding-top")}
            ${cssRem(p[s.id + "pr"] || p[s.id + "px"], "padding-right")}
            ${cssRem(p[s.id + "pb"] || p[s.id + "py"], "padding-bottom")}
            ${cssRem(p[s.id + "pl"] || p[s.id + "px"], "padding-left")}

            ${cssRem(p[s.id + "m"], "margin")}
            ${cssRem(p[s.id + "mt"] || p[s.id + "my"], "margin-top")}
            ${cssRem(p[s.id + "mr"] || p[s.id + "mx"], "margin-right")}
            ${cssRem(p[s.id + "mb"] || p[s.id + "my"], "margin-bottom")}
            ${cssRem(p[s.id + "ml"] || p[s.id + "mx"], "margin-left")}
            
            ${cssRem(p[s.id + "borderRadius"], "border-radius")}
        }`;
    })}
`;

export default Content;
