import React from "react";
import styled from "styled-components";

import Content from "../../atoms/Content";
import Label from "../../atoms/Label";
import Image from "../../atoms/Image";

import crossIcon from "../../../assets/images/docme/close--filled.svg";
import plusIcon from "../../../assets/images/docme/add--filled.svg";
import imgArrowRightBlack from "../../../assets/images/docme_redesigned/arrow_right_black.svg";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";

const HealthPlan = () => {
  const features = [
    {
      section: [
        {
          title: "Manage your Blood Pressure",
          details:
            "1 in 4 people worldwide have clinically elevated blood pressure, a condition often referred to as the “silent killer” because it is asymptomatic. To effectively manage blood pressure, the first crucial step is monitoring it regularly. Start by taking a free assessment today to gain valuable insights into your blood pressure status and learn more about how to manage it effectively.",
          blueTags: [
            "Personalised vital sign tracking",
            "Link your health insurance/GP",
            "Contextual Health Recommendations",
            "Professional health services & support",
          ],
          greyTags: ["Blood Pressure"],
        },
        {
          title: "Manage your stress",
          extra: "COMING SOON",
          details:
            "Manage stress with the DocMe app. It monitors HRV and blood pressure, giving insights into your stress levels. Use the data to make informed decisions and implement targeted stress-reduction techniques. The DocMe app empowers you to proactively address stress, promoting overall well-being. ",
          greenTags: ["Stress levels"],
          greyTags: [
            "Heart Rate",
            "Respiratory Rate",
            "HRV",
            "Blood Oxygen",
            "Blood Pressure",
          ],
        },
        {
          title: "Get your contraception plan online",
          extra: "COMING SOON",
          details:
            "DocMe offers a convenient and accessible way to get contraception plans on the app. It involves assessing your blood pressure and other cardiovascular health concerns, providing personalized advice, and access to contraception options without in-person visits. DocMe enables you to manage your reproductive health conveniently, privately, and from home.",
          blueTags: [
            "Contraception delivery plan",
            "Contextual Health Recommendations",
            "Professional health services & support",
          ],
          greyTags: ["Blood Pressure"],
        },
        {
          title: "Get active with workout tracking",
          extra: "COMING SOON",
          details:
            "The DocMe app creates a personalized data-driven workout plan for a tailored exercise experience. By analyzing your cardiovascular health, fitness levels, body composition, and exercise preferences, the app customizes workout plans to optimize results. It targets specific areas for improvement, adapts to progress, and offers a comprehensive fitness experience.",
          greenTags: ["Workouts Tracking", "Movement tracking"],
          greyTags: [
            "Heart Rate",
            "Respiratory Rate",
            "HRV",
            "Blood Oxygen",
            "Blood Pressure",
          ],
        },
      ],
    },
  ];
  return (
    <Content mx="8vw" t_mx="20vw" w="65%">
      <Label
        bolder
        align="left"
        w="90vw"
        t_w="44vw"
        t_size="3vw"
        size="7vw"
        t_display="block"
        display="block"
        t_mb="6vw"
        mb="6vw"
        fontColor="#001242"
      >
        Take your wellness & health
        to the next level
      </Label>
      {features.map((item, i) => (
        <Content key={i} mb="0vw">
          {/*<Hr style={{ marginTop: "1vw" }} />*/}
          <Accordion allowMultipleExpanded allowZeroExpanded>
            <Content t_mb="0vw" mb="0vw ">
              {item.section.map((sectionItem) => (
                <AccordionItem key={sectionItem.id}>
                  <Content t_my="2vw" my="4vw">
                    <AccordionItemHeading style={{ cursor: "pointer" }}>
                      <AccordionItemButton>
                        <Content
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                        >
                          <Label
                            cursor="pointer"
                            bolder
                            t_size="1.8vw"
                            size="5vw"
                            pr="5vw"
                            t_pr="0"
                          >
                            {sectionItem.title}{" "}
                            <sup style={{ color: "#667093" }}>
                              <Label t_size="0.8vw" size="2.5vw" bolder>
                                {sectionItem.extra}
                              </Label>
                            </sup>
                          </Label>
                          <AccordionItemState>
                            {({ expanded }) =>
                              !expanded ? (
                                <Image
                                  w="7vw"
                                  t_w="1.8vw"
                                  ml="auto"
                                  src={plusIcon}
                                  alt=""
                                />
                              ) : (
                                <Image
                                  w="7vw"
                                  t_w="1.8vw"
                                  ml="auto"
                                  src={crossIcon}
                                  alt=""
                                />
                              )
                            }
                          </AccordionItemState>
                        </Content>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <Content
                        display="flex"
                        flexDirection="column"
                        t_flexDirection="row"
                        justifyContent="space-between"
                        t_pt="3vw"
                        pt="6vw"
                        t_pb="0.5vw"
                        pb="6vw"
                      >
                        <Content
                          display="flex"
                          flexDirection="column"
                          w="100%"
                          t_w="52%"
                        >
                          <Label
                              medium
                            t_size="1.2vw"
                            size="3.5vw"
                            t_lineHeight="1.9vw"
                          >
                            {sectionItem.details}
                          </Label>
                        </Content>
                        <Content
                          display="flex"
                          flexDirection="column"
                          t_w="52%"
                        >
                          <Content
                            display="flex"
                            pt="5vw"
                            t_pt="0"
                            alignItems="flex-start"
                            flexWrap="wrap"
                            t_pl="3vw"
                          >
                            <Content
                              display="flex"
                              alignItems="flex-start"
                              flexWrap="wrap"
                            >
                              {sectionItem.blueTags?.map((t, j) => (
                                <Label
                                  bolder
                                  bgColor="#00E2FF34"
                                  size="3vw"
                                  t_size="0.9vw"
                                  t_my="0.3vw"
                                  my="0.6vw"
                                  mx="0.6vw"
                                  t_mx="0.3vw"
                                  t_py="0.6vw"
                                  py="1.2vw"
                                  px="1.65vw"
                                  t_px="0.85vw"
                                  borderRadius="500px"
                                >
                                  {t}
                                </Label>
                              ))}
                              {sectionItem.blueTags ? (
                                <Label
                                  fontColor="white"
                                  t_size="3vw"
                                  size="7vw"
                                  bgColor="#4F6E8C"
                                  opacity="0.25"
                                  t_my="0.3vw"
                                  my="0.6vw"
                                  mx="0.6vw"
                                  t_mx="0.3vw"
                                  t_w="5vw"
                                  w="10vw"
                                  h="6vw"
                                  t_h="2.5vw"
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  borderRadius="500px"
                                >
                                  +
                                </Label>
                              ) : (
                                <></>
                              )}
                            </Content>
                            <Content
                              display="flex"
                              alignItems="flex-start"
                              flexWrap="wrap"
                            >
                              {sectionItem.greenTags?.map((t, j) => (
                                <Label
                                  bolder
                                  bgColor="rgba(160, 255, 65, 0.6)"
                                  size="3vw"
                                  t_size="0.9vw"
                                  t_my="0.3vw"
                                  my="0.6vw"
                                  mx="0.6vw"
                                  t_mx="0.5vw"
                                  t_py="0.6vw"
                                  py="1.2vw"
                                  px="1.65vw"
                                  t_px="0.85vw"
                                  borderRadius="500px"
                                >
                                  {t}
                                </Label>
                              ))}
                              {sectionItem.greenTags ? (
                                <Label
                                  fontColor="white"
                                  t_size="3vw"
                                  size="7vw"
                                  bgColor="#4F6E8C"
                                  opacity="0.25"
                                  t_my="0.3vw"
                                  my="0.6vw"
                                  mx="0.6vw"
                                  t_mx="0.3vw"
                                  t_w="5vw"
                                  w="10vw"
                                  h="6vw"
                                  t_h="2.5vw"
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  borderRadius="500px"
                                >
                                  +
                                </Label>
                              ) : (
                                <></>
                              )}
                            </Content>
                            <Content
                              display="flex"
                              alignItems="flex-start"
                              flexWrap="wrap"
                            >
                              {sectionItem.greyTags.map((t, j) => (
                                <Label
                                  bolder
                                  bgColor="#4F6E8C"
                                  size="3vw"
                                  t_size="0.9vw"
                                  fontColor="#fff"
                                  opacity="0.6"
                                  t_my="0.3vw"
                                  my="0.6vw"
                                  mx="0.6vw"
                                  t_mx="0.3vw"
                                  t_py="0.6vw"
                                  py="1.2vw"
                                  px="1.65vw"
                                  t_px="0.85vw"
                                  borderRadius="500px"
                                >
                                  {t}
                                </Label>
                              ))}
                            </Content>
                          </Content>
                        </Content>
                      </Content>
                    </AccordionItemPanel>
                  </Content>
                  <Hr />
                </AccordionItem>
              ))}
            </Content>
          </Accordion>
        </Content>
      ))}
        {/*<Content t_w="100%" t_pt="1.5vw" pt="3vw" display="flex" justifyContent="flex-end">*/}
        {/*    <Content display="flex" flexDirection="column">*/}
        {/*        <Label bolder t_size="1.2vw">Get Health for $2.99</Label>*/}
        {/*        <Label t_size="0.8vw" size="2.8vw">Try 1 month for free</Label>*/}
        {/*    </Content>*/}
        {/*    <Image  src={imgArrowRightBlack} pl="1.2vw" t_w="2.7vw" t_h="2.7vw" />*/}
        {/*</Content>*/}
    </Content>
  );
};
const Hr = styled.hr`
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
`;

export default HealthPlan;
