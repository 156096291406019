import React from "react"
import styled from "styled-components"

import { screens } from "../helpers/device"
import { css, cssRem } from "../helpers/sizes"


const Image = props => (
    <ImageComponent {...props} />
)

const ImageComponent = styled.img`

    ${screens.map(s => {
        return p => `@media (min-width:${s.min}) { 
            ${css(p[s.id + 'opacity'], "opacity")}
            ${css(p[s.id + "borderRadius"], "border-radius")}
            ${css(p[s.id + "display"], "display")}
            ${css(p[s.id + "alignItems"], "align-items")}

            ${cssRem(p[s.id + 'w'], "width")}
            ${cssRem(p[s.id + 'h'], "height")}
            ${cssRem(p[s.id + 'cursor'], "cursor")}
            
            ${cssRem(p[s.id + 'p'], "padding")}
            ${cssRem((p[s.id + 'pt'] || p[s.id + 'py']), "padding-top")}
            ${cssRem((p[s.id + 'pr'] || p[s.id + 'px']), "padding-right")}
            ${cssRem((p[s.id + 'pb'] || p[s.id + 'py']), "padding-bottom")}
            ${cssRem((p[s.id + 'pl'] || p[s.id + 'px']), "padding-left")}

            ${cssRem(p[s.id + "top"], "top")}
            ${cssRem(p[s.id + "right"], "right")}
            ${cssRem(p[s.id + "bottom"], "bottom")}
            ${cssRem(p[s.id + "left"], "left")}
            ${css(p[s.id + "zIndex"], "z-index")}
            ${css(p[s.id + "position"], "position", "relative")}

            ${cssRem(p[s.id + 'm'], "margin")}
            ${cssRem((p[s.id + 'mt'] || p[s.id + 'my']), "margin-top")}
            ${cssRem((p[s.id + 'mr'] || p[s.id + 'mx']), "margin-right")}
            ${cssRem((p[s.id + 'mb'] || p[s.id + 'my']), "margin-bottom")}
            ${cssRem((p[s.id + 'ml'] || p[s.id + 'mx']), "margin-left")}
        }`     
    })}
`

export default Image